import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AuthService from '../../services/auth';
import LoadingSpinner from '../../components/LoadingSpinner';
import homePath from '../../utils/homePath';

import { SwalWithCustomButtons } from '../../vendors/customSwal';

const Auth = new AuthService();

const Random = {
  _pattern: /[a-zA-Z0-9_\-\+\.]/,
  _getRandomByte: function () {
    if (window.crypto && window.crypto.getRandomValues) {
      var result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    } else if (window.msCrypto && window.msCrypto.getRandomValues) {
      var result = new Uint8Array(1);
      window.msCrypto.getRandomValues(result);
      return result[0];
    } else {
      return Math.floor(Math.random() * 256);
    }
  },

  generate: function (length) {
    return Array.apply(null, { length: length })
      .map(function () {
        var result;
        while (true) {
          result = String.fromCharCode(this._getRandomByte());
          if (this._pattern.test(result)) {
            return result;
          }
        }
      }, this)
      .join('');
  },
};

const RedirectCustom = () => {
  const url = homePath.defaultPathUrl();
  document.location.href = url;
  // maybe can add spinner while loading
  return null;
};

const RedirectCustomHasUrl = (url) => {
  if (url === '') {
    url = homePath.defaultPathUrl();
  }
  console.log(url);
  document.location.href = url;
  // maybe can add spinner while loading
  return null;
};

const sso_logout_url = (access_id) => {
  let url = '';
  if (access_id !== null) {
    url = `https://portal.torchchurch.com/InternalSys/security_control_ssor.aspx?acttp=r&access_id=${access_id}&rnd=${Random.generate(
      16
    )}`;
  }
  return url;
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthentication] = useState(null);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    const lifetime = Auth.getLifetime();
    // console.log(lifetime);
    // ComponentDidMount
    const isUpdate = async () => {
      if (lifetime < 60 * 5) {
        try {
          const result = await Auth.updateToken();
          if (result) {
            setAuthentication(result);
          } else {
            return SwalWithCustomButtons.fire({
              title: 'Oops..',
              text: '驗証已過期，請重新登入',
              icon: 'error',
              heightAuto: false,
            }).then(() => {
              const url = sso_logout_url(Auth.getProfile().access_id);
              Auth.logout();
              RedirectCustomHasUrl(url);
            });
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        setAuthentication(lifetime);
      }
      setLoadingComplete(true);
    };
    // run login function
    if (Auth.isAuthenticated() === true) {
      isUpdate();
    } else {
      setLoadingComplete(true);
    }

    return () => {
      setAuthentication(null);
    };
  }, []);

  useEffect(() => {
    const checkLogin = () => {
      //console.log('What!!')
      // run login function
      if (interval && Auth.isAuthenticated() === false) {
        clearInterval(interval);
        SwalWithCustomButtons.fire({
          title: 'Oops..',
          text: '驗証已過期，請重新登入',
          icon: 'error',
          heightAuto: false,
        }).then(() => {
          const url = sso_logout_url(Auth.getProfile().access_id);
          Auth.logout();
          RedirectCustomHasUrl(url);
        });
      }
    };

    const interval = setInterval(() => {
      checkLogin();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (loadingComplete) {
    return !!authenticated ? <Outlet /> : <RedirectCustom />;
  } else {
    return <LoadingSpinner />;
  }
};

export default PrivateRoute;
