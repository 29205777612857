//Sweetalert2
import Swal from 'sweetalert2';

export const SwalWithCustomButtons = 
  Swal.mixin({
  confirmButtonColor: '#2196f3',    
  cancelButtonColor: '#e0e0e0',                  
  reverseButtons: true,
  heightAuto: false    
});

