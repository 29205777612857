import React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Box, Avatar } from '@mui/material';
//icon
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import PersonIcon from '@mui/icons-material/Person';
//custom css
import styles from './styles.module.scss';
import apiCaller from '../../utils/apiCaller';
const MemberIcon = ({ if_audit = 0, if_nf = 0, memberImage = '' }) => {
  const [img, setImg] = useState('');

  const fetchImage = async (imageUrl) => {
    try {
      const res = await apiCaller.apiFetchFile(imageUrl);
      const imageBlob = await res.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setImg(imageObjectURL);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (memberImage !== '') {
      // const memberImage = "https://i.imgur.com/fHyEMsl.jpg";
      console.log(`memberImage`, memberImage);
      fetchImage(memberImage);
    } else {
      setImg('');
    }
  }, [memberImage]);

  let icon = <PersonIcon />;
  let iconStyle = '';

  if (Number(if_audit) === 1) {
    iconStyle = styles.audit;
    icon = (
      <TransferWithinAStationIcon
        fontSize={memberImage === '' ? 'default' : 'small'}
      />
    );
  } else if (Number(if_nf) === 1) {
    iconStyle = styles.nf;
    icon = <WhatshotIcon fontSize={memberImage === '' ? 'default' : 'small'} />;
  } else {
    iconStyle = styles.member;
    icon = <PersonIcon fontSize={memberImage === '' ? 'default' : 'small'} />;
  }

  if (img === '') {
    return <Box className={cx(styles.box, iconStyle)}>{icon}</Box>;
  } else {
    return (
      <Box>
        <Avatar alt="member image" className={iconStyle} src={img} />
      </Box>
    );
  }
};

MemberIcon.prototype = {
  if_audit: PropTypes.number,
  if_nf: PropTypes.number,
  memberImage: PropTypes.string,
};

export default MemberIcon;
