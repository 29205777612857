import React, { useState, useEffect } from 'react';
import cx from 'classnames';

// import fblogo from '../../images/facebook-logo.png';
// import iglogo from '../../images/instagram-logo.png';
// import youtubelogo from '../../images/youtube-logo.png';
// // import firelogo from '../../images/fire.png';
// import domainlogo from '../../images/domain.png'
import { ReactComponent as Domainlogo } from '../../images/domain.svg';
import { ReactComponent as FBlogo } from '../../images/facebook.svg';
import { ReactComponent as Instagramlogo } from '../../images/instagram.svg';
import { ReactComponent as Youtubelogo } from '../../images/youtube.svg';

// import WhatshotIcon from '@material-ui/icons/Whatshot';
//custom css
import styles from './styles.module.scss';

import AuthService from '../../services/auth';
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const Auth = new AuthService();

const Footer = () => {
  const [info, setInfo] = useState({});
  const urlRewriteId = localStorage.getItem('urlRewriteId')
    ? localStorage.getItem('urlRewriteId')
    : 'torchchurch';
  useEffect(() => {
    let apiUrl = config.acts29_church_info_url;
    if (Auth.isAuthenticated()) {
      apiUrl = config.acts29_church_info_app_url;
    }

    apiCaller
      .apiFetch(apiUrl, {
        method: 'POST',
        body: JSON.stringify({ acts29_church_url_rewrite_id: urlRewriteId }),
      })
      .then((response) => {
        setInfo(response.Result[0]);
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  }, [urlRewriteId]);

  return (
    <footer className={cx(styles.footer)}>
      <div className={styles.outer_footer}>
        <div className={styles.logo}>
          <div className={styles.logo_img}></div>
        </div>
        <div className={styles.info}>
          <span>
            {info.acts29_church_name} {info.acts29_church_address}
          </span>
          <span>
            TEL. {info.acts29_church_phone1} FAX. {info.acts29_church_fax}
          </span>
        </div>
        <div className={styles.social_media}>
          <a
            href={info.acts29_church_website}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.item}>
              {/* <img src={domainlogo} alt="火把教會官網" /> */}
              <Domainlogo className={styles.svg} alt="火把教會官網" />
            </div>
          </a>
          <a href={info.fb} target="_blank" rel="noopener noreferrer">
            <div className={styles.item}>
              {/* <img src={fblogo} alt="火把教會FB" /> */}
              <FBlogo className={styles.svg} alt="火把教會FB" />
            </div>
          </a>
          <a href={info.ig} target="_blank" rel="noopener noreferrer">
            <div className={styles.item}>
              {/* <img src={iglogo} alt="火把教會IG" /> */}
              <Instagramlogo className={styles.svg} alt="火把教會IG" />
            </div>
          </a>
          <a href={info.youtube} target="_blank" rel="noopener noreferrer">
            <div className={styles.item}>
              {/* <img src={youtubelogo} alt="火把教會youtube" /> */}
              <Youtubelogo className={styles.svg} alt="火把教會youtube" />
            </div>
          </a>
        </div>
      </div>
      <div className={styles.copyright}>
        火把教會資訊系統 Copyright © Torch Covenant Church 版權所有 All rights
        reserved.
      </div>
    </footer>
  );
};

export default Footer;
