import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//Material-UI
import {
  Container,
  Box,
  Button,
  Grid,
  FormLabel,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';

//Sweetalert2
import { SwalWithCustomButtons } from '../../vendors/customSwal';

//custom css
import styles from './styles.module.scss';
//import component
import Header from '../../components/header';
import Footer from '../../components/footer';
import LoadingSpinner from '../../components/LoadingSpinner';
//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const AccountInfoChange = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [profileDB, setProfileDB] = useState({});
  const [editName, setEditName] = useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    //api
    apiCaller
      .apiFetch(config.account_profile_url, {
        method: 'POST',
        body: '',
      })
      .then((response) => {
        setProfile(response.Result[0]);
        setProfileDB(response.Result[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  }, []);

  const handleAccountfp = () => {
    const path = '/InternalSys/accountfp';
    navigate(path);
  };

  const handleEditClick = () => {
    setEditName(!editName);
  };

  const handleClearClick = () => {
    setProfile({ ...profile, user_name: profileDB.user_name });
    setEditName(!editName);
  };

  const handleProfileChange = (e) => {
    const keys = e.target.name;
    const value = e.target.value;
    setProfile({ ...profile, [keys]: value });
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    //account_profile_handle_url
    apiCaller
      .apiFetch(config.account_profile_handle_url, {
        method: 'POST',
        body: JSON.stringify(profile),
      })
      .then((response) => {
        setIsLoading(false);
        SwalWithCustomButtons.fire({
          title: '帳號資料已更新',
          icon: 'success',
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  };

  return (
    <div className={styles.page}>
      {isLoading && <LoadingSpinner />}
      <Header />
      <div className={styles.wrapper}>
        <Container maxWidth="md" className={styles.container}>
          <Box className={styles.page_title}>變更資訊</Box>
          <Box my={2} className={styles.box_body}>
            <Box className={styles.title_box}>
              <Box className={styles.title}>個人資料</Box>
              <Box>
                <button
                  className={styles.outline_btn}
                  onClick={handleAccountfp}
                >
                  變更密碼
                </button>
              </Box>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box className={styles.content}>
                <Grid container>
                  <Grid item xs={12} sm={2} className={styles.form_text}>
                    <FormLabel className={styles.form_lable_text}>
                      *您的帳號
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={10} className={styles.form_input}>
                    <Box className={styles.blue_text}>{profile.userid}</Box>
                  </Grid>
                  <Grid item xs={12} sm={2} className={styles.form_text}>
                    <FormLabel className={styles.form_lable_text}>
                      *真實姓名
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={10} className={styles.form_input}>
                    {editName ? (
                      <React.Fragment>
                        <FormControl margin="dense" variant="outlined">
                          <OutlinedInput
                            name="user_name"
                            value={profile.user_name || ''}
                            onChange={handleProfileChange}
                            required
                          />
                        </FormControl>
                        <Button
                          component="span"
                          color="secondary"
                          startIcon={<ClearIcon />}
                          onClick={handleClearClick}
                        >
                          取消
                        </Button>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Box component="span" className={styles.black_text}>
                          {profile.user_name}
                        </Box>
                        <Button
                          component="span"
                          color="primary"
                          startIcon={<EditIcon />}
                          onClick={handleEditClick}
                        >
                          修改
                        </Button>
                      </React.Fragment>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={2} className={styles.form_text}>
                    <FormLabel className={styles.form_lable_text}>
                      *聯絡電話
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={10} className={styles.form_input}>
                    <FormControl margin="dense" variant="outlined">
                      <OutlinedInput
                        name="tel"
                        type="tel"
                        value={profile.tel || ''}
                        onChange={handleProfileChange}
                        inputProps={{
                          pattern: '[0-9]{9,}',
                        }}
                        required
                      />
                    </FormControl>
                    <FormHelperText>手機/電話+區碼</FormHelperText>
                  </Grid>
                  <Grid item xs={12} sm={2} className={styles.form_text}>
                    <FormLabel className={styles.form_lable_text}>
                      *電子郵件
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={10} className={styles.form_input}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <OutlinedInput
                        name="email"
                        type="email"
                        value={profile.email || ''}
                        onChange={handleProfileChange}
                        required
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box className={styles.btn_box}>
                <button className={styles.fill_btn} type="submit">
                  資料變更
                </button>
              </Box>
            </form>
          </Box>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default AccountInfoChange;
