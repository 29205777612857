import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
//Material-UI
import { Paper } from '@mui/material';
//icon
import ListAltIcon from '@mui/icons-material/ListAlt';

//custom css
import styles from './styles.module.scss';
//components
import CHeader from '../../components/card/cHeader';
import CFooter from '../../components/card/cFooter';
import NewsItem from '../../components/card/newsItem';
import NoData from '../../components/card/noData';
import CLoading from '../../components/card/cLoading';
//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const GroupNews = () => {
  // const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  // const [circularloading, setCircularLoading] = useState(false);

  const fetcher = ([url, data]) =>
    apiCaller
      .apiFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .then((response) => {
        return response.Result;
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
        return err;
      });

  const { data: news, isLoading: circularLoading } = useSWR(
    [config.page_website_group_news_url, { if_top: false }],
    fetcher,
    { fallbackData: [] }
  );

  return (
    <Paper className={styles.card}>
      <CHeader titleName="公告通知" imgPath={<ListAltIcon />} />
      <div className={styles.card_content}>
        {circularLoading && <CLoading />}
        {!circularLoading && news.length <= 0 && <NoData />}
        {!circularLoading &&
          news.length > 0 &&
          news
            .slice((page - 1) * 4, (page - 1) * 4 + 4)
            .map((item, index) => <NewsItem key={index} newsContent={item} />)}
      </div>
      <CFooter
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(news.length / 4)}
      />
    </Paper>
  );
};

export default GroupNews;
