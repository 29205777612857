import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
//Material-UI
import { Box, Link, Menu, MenuItem, Divider, Tooltip } from '@mui/material';
import { MoreHoriz, MoodTwoTone } from '@mui/icons-material';

//custom css
import styles from './styles.module.scss';
//custom js
import homePath from '../../../utils/homePath';

//components
import MemberIcon from '../../memberIcon';
import MemberTag from '../../memberTag';

const contentFormat = (inputObj) => {
  const outputObj = {};
  Object.keys(inputObj).forEach((key) => {
    switch (key) {
      case 'member_aid':
        outputObj.memberAid = inputObj[key];
        break;
      case 'member_line_image_src':
        outputObj.memberImage = inputObj[key];
        break;
      case 'member_name':
        outputObj.memberName = inputObj[key];
        break;
      case 'email':
        outputObj.memberEmail = inputObj[key];
        break;
      case 'cell_phone':
        outputObj.memberCellPhone = inputObj[key];
        break;
      case 'if_follow_sun':
        outputObj.ifFollowSun = inputObj[key];
        break;
      case 'if_follow_cg':
        outputObj.ifFollowCG = inputObj[key];
        break;
      case 'if_audit':
        outputObj.ifAudit = inputObj[key];
        break;
      case 'if_nf':
        outputObj.ifNF = inputObj[key];
        break;
      default:
        outputObj[key] = inputObj[key];
    }
  });
  return outputObj;
};

const Memberitem = ({ memberContent = {} }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const memberInfo = contentFormat(memberContent);

  const handleClick = () => {
    const path = homePath.PRMPathUrl('member_detail');
    localStorage.setItem('MID', memberInfo.memberAid);
    localStorage.setItem('ref_com', 'memberListRef');
    document.location.href = path;
  };

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = (event) => {
    setAnchorEl(null);
  };

  const handleTransferClick = () => {
    const path = homePath.PRMPathUrl('member_transfer');
    localStorage.setItem('MID', memberInfo.memberAid);
    localStorage.setItem('ref_com', 'memberListRef');
    handleMoreClose();
    document.location.href = path;
  };

  const handleLeaveClick = () => {
    const path = homePath.PRMPathUrl('member_leave');
    localStorage.setItem('MID', memberInfo.memberAid);
    localStorage.setItem('ref_com', 'memberListRef');
    handleMoreClose();
    document.location.href = path;
  };

  const hanldeMemberSQClick = () => {
    const path = homePath.PRMPathUrl('member_survey/add');
    localStorage.setItem('MID', memberInfo.memberAid);
    localStorage.setItem('ref_com', 'memberListRef');
    handleMoreClose();
    document.location.href = path;
  };

  const handleTestimonyClick = () => {
    const path = homePath.PRMPathUrl('testimony/add');
    localStorage.setItem('MID', memberInfo.memberAid);
    localStorage.setItem('ref_com', 'memberListRef');
    handleMoreClose();
    document.location.href = path;
  };

  return (
    <Box className={cx(styles.item_layout)}>
      <Box className={styles.front} onClick={handleClick}>
        <Box className={cx(styles.state)}>
          <MemberIcon
            if_audit={memberInfo.ifAudit}
            if_nf={memberInfo.ifNF}
            memberImage={memberInfo.memberImage}
          />
        </Box>
        <Box className={styles.info}>
          <Box>
            <Box
              component="span"
              className={cx(styles.text_normal, styles.blue)}
            >
              {memberInfo.memberName}
            </Box>
            <MemberTag if_audit={memberInfo.ifAudit} if_nf={memberInfo.ifNF} />
          </Box>
          <Box className={cx(styles.ellipsis)}>
            <Box
              component="span"
              className={cx(styles.text_normal, styles.screen_display_b)}
            >
              <a
                href={`tel: ${memberInfo.memberCellPhone}`}
                target="_top"
                data-rel="external"
                className={cx(styles.text_normal, styles.black)}
              >
                {memberInfo.memberCellPhone}
              </a>
            </Box>
            <Box component="span" className={cx(styles.screen_display_b)}>
              <a
                href={`mailto: ${memberInfo.memberEmail}`}
                target="_top"
                data-rel="external"
                className={cx(styles.text_normal, styles.black)}
              >
                {memberInfo.memberEmail}
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className={cx(styles.back)}>
        <Box className={styles.follow_info}>
          <Box className={styles.d_f}>
            <Box component="span" mr={2}>
              主日狀態
            </Box>
            <Tooltip
              title={memberInfo.ifFollowSun === 0 ? '穩定' : '不穩定'}
              placement="right"
            >
              <MoodTwoTone
                className={
                  memberInfo.ifFollowSun === 0 ? styles.g_light : styles.disable
                }
              />
            </Tooltip>
          </Box>
          <Box className={styles.d_f}>
            <Box component="span" mr={2}>
              小組狀態
            </Box>
            {memberInfo.ifFollowCG >= 0 && (
              <Tooltip
                title={memberInfo.ifFollowCG === 0 ? '穩定' : '不穩定'}
                placement="right"
              >
                <MoodTwoTone
                  className={
                    memberInfo.ifFollowCG === 0
                      ? styles.y_light
                      : styles.disable
                  }
                />
              </Tooltip>
            )}
            {memberInfo.ifFollowCG < 0 && <span>N/A</span>}
          </Box>
        </Box>
        <Box>
          {/* 轉移中的成員，無法有選單的功能 */}
          <Link
            aria-controls="simple-menu"
            aria-haspopup="true"
            color={memberInfo.ifAudit === 0 ? 'primary' : 'inherit'}
            onClick={memberInfo.ifAudit === 0 ? handleMoreClick : null}
          >
            <MoreHoriz />
          </Link>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMoreClose}
            PaperProps={{
              style: {
                backgroundColor: '#6496db',
                color: '#ffffff',
              },
            }}
            MenuListProps={{
              style: {
                paddingTop: '0px',
                paddingBottom: '0px',
              },
            }}
          >
            <MenuItem onClick={handleTransferClick}>轉移</MenuItem>
            <Divider style={{ backgroundColor: '#b7ceed' }} />
            <MenuItem onClick={handleLeaveClick}>離開</MenuItem>
            <Divider style={{ backgroundColor: '#b7ceed' }} />
            {/* <MenuItem style={{color: '#cbcbcb'}}>填寫健檢表(未開放)</MenuItem> */}
            <MenuItem onClick={hanldeMemberSQClick}>填寫健檢表</MenuItem>
            <Divider style={{ backgroundColor: '#b7ceed' }} />
            <MenuItem onClick={handleTestimonyClick}>見證回報</MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

Memberitem.prototype = {
  memberContent: PropTypes.shape({
    member_aid: PropTypes.number,
    member_name: PropTypes.string,
    email: PropTypes.string,
    cell_phone: PropTypes.string,
    if_follow_sun: PropTypes.number,
    if_follow_cg: PropTypes.number,
    if_audit: PropTypes.number,
    if_nf: PropTypes.number,
  }),
};

export default Memberitem;
