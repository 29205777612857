import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Box } from '@mui/material';
//custom css
import styles from './styles.module.scss';

const MemberTag = ({ if_audit = 0, if_nf = 0 }) => {
  let tagStyle = '';
  let tagText = '';

  if (Number(if_audit) === 1) {
    tagStyle = styles.audit;
    tagText = '轉移中';
  } else if (Number(if_nf) === 1) {
    tagStyle = styles.nf;
    tagText = '新朋友';
  } else {
    tagStyle = styles.member;
  }

  if (Number(if_audit) === 0 && Number(if_nf) === 0) {
    return null;
  }

  return (
    <Box component="span" className={cx(styles.tag, tagStyle)}>
      {tagText}
    </Box>
  );
};

MemberTag.prototype = {
  if_audit: PropTypes.number,
  if_nf: PropTypes.number,
};

export default MemberTag;
