export const test_data=[
    {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:1,
        booking_status_text:'登記取消',        
        group_share_url:'123'
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/18',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }, {        
        booking_aid:1,
        datetime_aid: 2,
        booking_date:'2020/02/26',
        booking_start_time:'13:30',
        booking_end_time:'17:00',
        location_name:'10樓 教室-1',
        booking_type_text:'事工聚集',
        booking_status:0,
        booking_status_text:'登記成功',        
        group_share_url:''
    }
]