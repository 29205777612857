import React, { useState, useEffect } from 'react';

//Material-UI
import { Paper } from '@mui/material';
//icon
import WhatshotIcon from '@mui/icons-material/Whatshot';

//custom css
import styles from './styles.module.scss';
import CHeader from '../../components/card/cHeader';
import CFooter from '../../components/card/cFooter';
import NewsItem from '../../components/card/newsItem';
import NoData from '../../components/card/noData';
import CLoading from '../../components/card/cLoading';
//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const Topgroupnews = () => {
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [circularloading, setCircularLoading] = useState(false);

  useEffect(() => {
    setCircularLoading(true);
    //page_website_group_news_url
    apiCaller
      .apiFetch(config.page_website_group_news_url, {
        method: 'POST',
        body: JSON.stringify({ if_top: true }),
      })
      .then((response) => {
        setNews(response.Result);
        setCircularLoading(false);
      })
      .catch((err) => {
        setCircularLoading(false);
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  }, []);

  return (
    <Paper className={styles.card}>
      <CHeader titleName="釘選資訊" imgPath={<WhatshotIcon />} />
      <div className={styles.card_content}>
        {circularloading && <CLoading />}
        {!circularloading && news.length <= 0 && <NoData />}
        {!circularloading &&
          news.length > 0 &&
          news
            .slice((page - 1) * 4, (page - 1) * 4 + 4)
            .map((item, index) => <NewsItem key={index} newsContent={item} />)}
      </div>
      <CFooter
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(news.length / 4)}
      />
    </Paper>
  );
};

export default Topgroupnews;
