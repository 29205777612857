import React, { useState, useEffect } from 'react';
// import { withRouter } from 'react-router';
import cx from 'classnames';
import AuthService from '../../services/auth';
import homePath from '../../utils/homePath';

// Components
import Menu from '../menu';
import { Button } from '@mui/material';
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  MenuOutlined as MenuOutlinedIcon,
  HomeWork as HomeWorkIcon,
} from '@mui/icons-material';

//custom css
import styles from './styles.module.scss';

const Auth = new AuthService();

const Header = ({ allowLogin = true }) => {
  const [show, setShow] = useState(false);
  const [profile, setProfile] = useState(null);
  // console.log('----haeder init----')

  const handleHomePage = () => {
    if (allowLogin) {
      localStorage.removeItem('ref_com');
      const url = homePath.homePathUrl();
      document.location.href = url;
    }
  };

  const handleOSPage = () => {
    const url = 'https://www.torchchurch.com/';
    document.location.href = url;
  };

  useEffect(() => {
    setProfile(Auth.getProfile());
    // console.log('header effect')
  }, [show]);

  return (
    <header className={cx(styles.header)}>
      {/* {console.log('haeder render')} */}
      <div className={cx(styles.outer_header)}>
        <div className={cx(styles.logo)}>
          <span onClick={handleHomePage}>
            <div className={cx(styles.logo_img, styles.pointer)}></div>
          </span>
        </div>
        {allowLogin && (
          <nav className={cx(styles.nav)}>
            {Auth.isAuthenticated() && (
              <AccountCircleOutlinedIcon
                fontSize="large"
                className={styles.profile}
              />
            )}
            <div className={styles.login_text}>
              {Auth.isAuthenticated() &&
                profile &&
                profile.user_name + '，歡迎您！'}
              {!Auth.isAuthenticated() && '請登入領袖同工帳號'}
            </div>
            <span className={styles.pointer} onClick={() => setShow(!show)}>
              <MenuOutlinedIcon fontSize="large" />
            </span>
          </nav>
        )}
        {!allowLogin && (
          <nav className={cx(styles.nav)}>
            <Button
              variant="outlined"
              style={{ color: '#fff', borderColor: '#fff' }}
              startIcon={<HomeWorkIcon />}
              onClick={handleOSPage}
            >
              回火把官網
            </Button>
          </nav>
        )}
      </div>
      <Menu show={show} setShow={setShow} />
    </header>
  );
};
export default Header;
// export default withRouter(Header);
