import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
//Material-UI
import { Box, Tooltip } from '@mui/material';
//custom css
import styles from './styles.module.scss';
//custom js
import homePath from '../../../utils/homePath';

const contentFormat = (inputObj) => {
  const outputObj = {};
  Object.keys(inputObj).forEach((key) => {
    switch (key) {
      case 'audit_aid':
        outputObj.auditAid = inputObj[key];
        break;
      case 'status':
        outputObj.status = inputObj[key];
        break;
      case 'audit_title':
        outputObj.auditTitle = inputObj[key];
        break;
      case 'applicant_name':
        outputObj.applicantName = inputObj[key];
        break;
      case 'applicant_id':
        outputObj.applicantId = inputObj[key];
        break;
      case 'build_datetime':
        outputObj.buildDatetime = inputObj[key];
        break;
      default:
        outputObj[key] = inputObj[key];
    }
  });
  return outputObj;
};
//status
// 1:轉移
// 2:新朋友加入小組通知
// 805:小家長申請
// 804:小組長申請
// 803:區長申請
// 802:區牧申請
// 999:卸任

const Audititem = ({ auditContent, size = 'm' }) => {
  const auditInfo = contentFormat(auditContent);
  let type = {};

  if (auditInfo.auditAid === 0) {
    type = styles.padding;
  } else {
    switch (true) {
      case auditInfo.status === 1:
        type = styles.member;
        break;
      case auditInfo.status === 2:
        type = styles.new_friend;
        break;
      case auditInfo.status >= 800:
        type = styles.account;
        break;
      // case 802:
      // case 803:
      // case 804:
      // case 805:
      //         type = styles.account
      //     break;
      default:
        type = styles.account;
        break;
    }
  }

  let path = '';
  if (auditInfo.auditAid !== 0) {
    switch (true) {
      case auditInfo.status === 1:
        path = homePath.PRMPathUrl('signing/transfer_detail');
        break;
      case auditInfo.status >= 800:
        path = homePath.PRMPathUrl('signing/account');
        break;
      // case 802:
      // case 803:
      // case 804:
      // case 805:
      //         path = homePath.PRMPathUrl('signing/account')
      //     break;
      default:
        break;
    }
  }

  const handleClick = () => {
    // localStorage.setItem('AUID', auditInfo.auditAid);
    localStorage.setItem('ref_com', 'auditListRef');
    if (path !== '') {
      document.location.href = path + '/' + auditInfo.auditAid;
    }
  };

  return (
    <Box className={cx(styles.item_layout, type)} onClick={handleClick}>
      <Box className={styles.top}>
        <Tooltip title={auditInfo.auditTitle}>
          <Box
            className={
              size === 's'
                ? cx(styles.audit_title, styles.ellipsis, type)
                : cx(styles.audit_title, type)
            }
          >
            {auditInfo.auditTitle}
          </Box>
        </Tooltip>
        <Box className={styles.datetime}>
          {auditInfo.buildDatetime.split(' ', 1)}
        </Box>
      </Box>
      <Box className={cx(styles.dim_name)}>
        {auditInfo.applicantName}({auditInfo.applicantId})
      </Box>
    </Box>
  );
};

Audititem.prototype = {
  auditContent: PropTypes.shape({
    audit_aid: PropTypes.number,
    status: PropTypes.number,
    audit_title: PropTypes.string,
    applicant_name: PropTypes.string,
    applicant_id: PropTypes.string,
    build_datetime: PropTypes.string,
  }),
  size: PropTypes.string,
};

export default Audititem;
