import React, { useState, useEffect } from 'react';
import cx from 'classnames';
//Material-UI
import {
  Container,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ShareIcon from '@mui/icons-material/Share';

//custom js
import transform from '../../utils/transform';
//Sweetalert2
import { SwalWithCustomButtons } from '../../vendors/customSwal';
//custom css
import styles from './styles.module.scss';
//import component
import Header from '../../components/header';
import Footer from '../../components/footer';

import { test_data } from './test_data';

Date.prototype.addHours = function (h) {
  this.setTime(this.getTime() + h * 60 * 60 * 1000);
  return this;
};

const BookingStatusIcon = ({ bookingStatus }) => {
  let icon = '';
  if (bookingStatus === 0) {
    icon = (
      <CheckCircleOutlineIcon
        style={{ color: '#4caf50', marginRight: '3px' }}
      />
    );
  } else if (bookingStatus === 1) {
    icon = <HighlightOffIcon color="disabled" style={{ marginRight: '3px' }} />;
  } else if (bookingStatus === 2) {
    icon = (
      <ErrorOutlineIcon color="secondary" style={{ marginRight: '3px' }} />
    );
  }

  return icon;
};

const BookingItem = ({ bookingContent }) => {
  const line_share_text =
    '我釋出小組場地囉!%0D%0A' +
    bookingContent.booking_date +
    '(' +
    transform.dateWeekFormat(bookingContent.booking_date) +
    ')' +
    '(' +
    bookingContent.booking_start_time +
    '-' +
    bookingContent.booking_end_time +
    ')' +
    bookingContent.location_name;

  const handleDenied = () => {
    SwalWithCustomButtons.fire({
      title: '確認取消？',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '刪除',
      cancelButtonText: '取消',
    });
  };

  const allowDenied = (status) => {
    let result = false;
    //登記成功才能取消登記
    if (Number(status) === 0) {
      let bookingDatetime = new Date(
        bookingContent.booking_date +
          ' ' +
          bookingContent.booking_start_time +
          ':00'
      );
      let currentDate = new Date().addHours(2); //在登記時的前2個小時開始不能取消

      if (
        Date.parse(bookingDatetime).valueOf() <
        Date.parse(currentDate).valueOf()
      ) {
        result = false;
      } else {
        result = true;
      }
    }

    return result;
  };

  return (
    <Accordion className={styles.expansion_panel}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <BookingStatusIcon bookingStatus={bookingContent.booking_status} />
        <Box className={styles.font_size}>
          {bookingContent.booking_date.substr(5)} (
          {bookingContent.booking_start_time}-{bookingContent.booking_end_time})
          {bookingContent.location_name}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                登記編號
              </TableCell>
              <TableCell align="center">{bookingContent.booking_aid}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                地點
              </TableCell>
              <TableCell align="center">
                {bookingContent.location_name}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                日期
              </TableCell>
              <TableCell align="center">
                {bookingContent.booking_date}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                開始時間
              </TableCell>
              <TableCell align="center">
                {bookingContent.booking_start_time}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                結束時間
              </TableCell>
              <TableCell align="center">
                {bookingContent.booking_end_time}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                登記類型
              </TableCell>
              <TableCell align="center">
                {bookingContent.booking_type_text}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" align="center">
                登記狀態
              </TableCell>
              <TableCell align="center">
                {bookingContent.booking_status_text}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </AccordionDetails>
      <AccordionActions>
        {bookingContent.group_share_url !== '' &&
        bookingContent.booking_status === 1 ? (
          <Button
            component="a"
            variant="outlined"
            size="small"
            startIcon={<ShareIcon />}
            style={{ color: '#4caf50' }}
            href={`http://line.me/R/msg/text/?'${line_share_text}%0D%0A${bookingContent.group_share_url}`}
            target="_blank"
          >
            分享至LINE
          </Button>
        ) : null}
        {allowDenied(bookingContent.booking_status) && (
          <Button variant="outlined" size="small" onClick={handleDenied}>
            取消登記
          </Button>
        )}
      </AccordionActions>
    </Accordion>
  );
};

const BookingHistory = () => {
  const [bookingItem, setBookingItem] = useState([]);

  useEffect(() => {
    setBookingItem(test_data);
  }, []);

  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.wrapper}>
        <Container maxWidth="xl" className={styles.container}>
          <div className={cx(styles.d_title_font, styles.d_title)}>
            火把領袖同工資訊平台
          </div>
          <div className={cx(styles.d_subtitle_font, styles.d_subtitle)}>
            場地登記紀綠
          </div>
          <Box className={styles.body}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={1}
              className={styles.font_size}
            >
              圖示說明：
              <BookingStatusIcon bookingStatus={0} />
              <Box component="span">登記成功</Box>
              <BookingStatusIcon bookingStatus={2} />
              <Box component="span">登記審核中</Box>
              <BookingStatusIcon bookingStatus={1} />
              <Box component="span">登記取消</Box>
            </Box>
            <Box>
              {bookingItem.map((item, index) => (
                <BookingItem key={index} bookingContent={item} />
              ))}
            </Box>
          </Box>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default BookingHistory;
