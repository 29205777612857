import React from 'react';
import useMetaTags from 'react-metatags-hook';
import { Box, Container } from '@mui/material';
import img404 from '../../images/torchman_ 404.png';

import homePath from '../../utils/homePath';
//custom css
import styles from './styles.module.scss';
//import component
import Header from '../../components/header';
import Footer from '../../components/footer';

const NotFound = () => {
  useMetaTags(
    {
      metas: [{ name: 'robots', content: 'noindex,nofollow' }],
    },
    []
  );

  //回資訊平台
  const handleButtonBackClick = () => {
    const url = homePath.homePathUrl();
    document.location.href = url;
  };

  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.wrapper}>
        <Container maxWidth="sm" className={styles.container}>
          <Box display="flex" flexDirection="column" pt="1vh">
            <Box display="flex" justifyContent="center" mt={6}>
              <img
                className={styles.img}
                alt="Under development"
                src={img404}
              ></img>
            </Box>
            <div className={styles.text}>
              哎呀！網頁未找到，也許您可以先回到首頁！
            </div>
            {/* <Typography variant="h6" align="center">
                No match for <code>{location.pathname}</code>
              </Typography> */}
            <Box display="flex" justifyContent="center" mt={4}>
              <button className={styles.btn} onClick={handleButtonBackClick}>
                返回首頁
              </button>
            </Box>
          </Box>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
