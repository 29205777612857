import React, { useState, useEffect } from 'react';
//Material-UI
import { Paper, Divider } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';
//custom css
import styles from './styles.module.scss';
//custom js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

//components
import CHeader from '../../components/card/cHeader';
import CFooter from '../../components/card/cFooter';
import Audititem from '../../components/card/auditItem';
import NoData from '../../components/card/noData';
import CLoading from '../../components/card/cLoading';
import { useWindowSize } from '../../utils/useWindowSize';

const AuditListApproved = () => {
  const [audit, setAudit] = useState([]);
  const [page, setPage] = useState(1);
  const [circularloading, setCircularLoading] = useState(false);
  const size = useWindowSize();
  const splitItem = size.width >= 1200 ? 4 : 3;

  useEffect(() => {
    setCircularLoading(true);

    //audit_list_inprogress_approved
    apiCaller
      .apiFetch(config.audit_list_inprogress_approved, {
        method: 'POST',
        body: '',
      })
      .then((response) => {
        setAudit(response.Result);
        setPage(1);
        setCircularLoading(false);
      })
      .catch((err) => {
        setCircularLoading(false);
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  }, []);

  return (
    <Paper className={styles.card}>
      <CHeader titleName="審核進度" imgPath={<HowToRegIcon />} />
      <div className={styles.card_content}>
        {circularloading && <CLoading />}
        {!circularloading && audit.length === 0 && <NoData />}
        {!circularloading &&
          audit.length > 0 &&
          audit
            .slice((page - 1) * splitItem, (page - 1) * splitItem + splitItem)
            .map((item, index) => {
              return index + 1 === (page - 1) * splitItem + splitItem ||
                index ===
                  audit.slice(
                    (page - 1) * splitItem,
                    (page - 1) * splitItem + splitItem
                  ).length -
                    1 ? (
                <Audititem key={`a_${index}`} auditContent={item} size="s" />
              ) : (
                <React.Fragment key={index}>
                  <Audititem key={`a_${index}`} auditContent={item} size="s" />
                  <Divider key={`d_${index}`} variant="middle" />
                </React.Fragment>
              );
            })}
      </div>
      <CFooter
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(audit.length / splitItem)}
      />
    </Paper>
  );
};

export default AuditListApproved;
