import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
// import cx from 'classnames';
//Sweetalert2
//import {SwalWithCustomButtons} from '../../vendors/customSwal';

import AuthService from '../../services/auth';

const Auth = new AuthService();

const Logout = () => {
  useEffect(() => {
    Auth.logout();
  }, []);

  return (
    <Navigate
      to={{
        pathname: '/InternalSys/torchnews',
      }}
    />
  );
};

export default Logout;
