import React from 'react';
import useMetaTags from 'react-metatags-hook';
import cx from 'classnames';
import { Box, Container } from '@mui/material';
import img500 from '../../images/500_Internal_Server_Error.png';
//custom css
import styles from './styles.module.scss';
//import component
import Header from '../../components/header';
// import Footer from '../../components/footer'

const ErrorPage = () => {
  useMetaTags(
    {
      metas: [{ name: 'robots', content: 'noindex,nofollow' }],
    },
    []
  );

  return (
    <div className={styles.page}>
      <Header allowLogin={false} />
      <div className={styles.wrapper}>
        <Container maxWidth="sm" className={styles.container}>
          <Box display="flex" flexDirection="column" pt="1vh">
            <Box py={5} className={styles.title}>
              伺服器異常，請稍後再試...
            </Box>
            <Box display="flex" justifyContent="center" mt={6}>
              <img
                className={styles.img}
                alt="Under development"
                src={img500}
              ></img>
            </Box>
            {/* <div className={styles.text}>
                伺服器異常，請稍後再試！
              </div> */}
            {/* <Typography variant="h6" align="center">
                No match for <code>{location.pathname}</code>
              </Typography> */}
          </Box>
        </Container>
      </div>
      <footer className={cx(styles.footer)}>
        <div className={styles.copyright}>
          火把教會資訊系統 Copyright © Torch Covenant Church 版權所有 All rights
          reserved.
        </div>
      </footer>
      {/* <Footer/> */}
    </div>
  );
};

export default ErrorPage;
