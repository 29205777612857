const env = 'torch'; //影響services/httpStatus
let if_dev = false;
let host = '';
if (env === 'dev') {
  // host = 'http://localhost/torchchurch/Acts29/bkendAPI/bkendAPI';
  host = 'https://portal.torchchurch.com/dev/bkendAPI';
  if_dev = true;
}
if (env === 'torch') {
  host = 'https://portal.torchchurch.com/bkendAPI';
  if_dev = false;
}

const config = {
  env: env,
  if_dev: if_dev,
  //common
  auth_url: `${host}/api/auth/login`,
  reauth_url: `${host}/api/auth/reauth`,
  acfp_url: `${host}/api/auth/acfp/`,
  cg_list_url: `${host}/api/PrmOrganizationPrivilege/cg_list/`,
  cg_member_list_url: `${host}/api/PrmMember/cg_member_list/`,
  return_doption_by_dselect_url: `${host}/api/DOption/return_doption_by_dselect`,
  //footer
  acts29_church_info_url: `${host}/api/PrmOrganizationPrivilege/acts29_church_info/`,
  acts29_church_info_app_url: `${host}/api/PrmOrganizationPrivilege/acts29_church_info_app/`,
  //帳號權限AccountPrivilege
  account_profile_url: `${host}/api/AccountPrivilege/account_profile`,
  account_privilege_menu_url: `${host}/api/AccountPrivilege/account_privilege_menu`,
  account_group_privilege_list_url: `${host}/api/AccountPrivilege/account_group_privilege_list`,
  account_dashboard_view_url: `${host}/api/AccountPrivilege/account_dashboard_view`,
  account_profile_handle_url: `${host}/api/AccountPrivilege/account_profile_handle`,
  account_chgpw_url: `${host}/api/AccountPrivilege/account_chgpw`,
  account_group_privilege_info_url: `${host}/api/AccountPrivilege/account_group_privilege_info`,
  //dashboard common
  org_privilege_list_url: `${host}/api/PrmOrganizationPrivilege/org_privilege_list/`,
  //News
  acts29_church_page_website_group_news_url: `${host}/api/News/acts29_church_page_website_group_news`,
  page_website_group_news_url: `${host}/api/News/page_website_group_news`,
  web_article_info_url: `${host}/api/News/web_article_info`,
  //PrmMember
  cg_member_list_status_url: `${host}/api/PrmMember/cg_member_list_status`,
  cg_member_birthday_url: `${host}/api/PrmMember/cg_member_birthday/`,
  cg_member_list_tu_status_url: `${host}/api/PrmMember/cg_member_list_tu_status`,
  //PrmMemberAudit
  audit_list_url: `${host}/api/PrmMemberAudit/audit_list`,
  audit_list_inprogress_approved: `${host}/api/PrmMemberAudit/audit_list_inprogress_approved`,
  //PrmMeetingReport
  prm_meeting_report_data1_url: `${host}/api/PrmMeetingReport/prm_meeting_report_data1`,
  prm_meeting_report_data2_url: `${host}/api/PrmMeetingReport/prm_meeting_report_data2`,
};

export default config;
