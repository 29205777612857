import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
//Material-UI
import { Box } from '@mui/material';
//icon
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
//custom css
import styles from './styles.module.scss';

const CFooter = ({ page, totalPage, setPage, footerType = '0' }) => {
  //0:page and btn, 1:only page, 2:only btn, 3:only btn(week range)
  const handleBackPage = () => {
    if (page - 1 > 0) {
      setPage(page - 1);
    }
  };
  const handleNextPage = () => {
    if (page + 1 <= totalPage) {
      setPage(page + 1);
    }
  };
  const handleBackRange = () => {
    setPage(page - 5);
  };
  const handleNextRange = () => {
    if (page + 5 <= 0) {
      setPage(page + 5);
    }
  };

  return (
    <React.Fragment>
      {footerType === '0' && (
        <Box className={styles.footer_box}>
          {totalPage > 0 && (
            <Box component="div" className={styles.page_text}>
              <span className={styles.cur}>{page}</span> / {totalPage} 頁數
            </Box>
          )}
          {totalPage > 1 && (
            <Box component="div">
              <button
                className={cx(styles.page_icon, styles.back_icon)}
                onClick={handleBackPage}
              >
                <KeyboardArrowLeft />
              </button>
              <button
                className={cx(styles.page_icon, styles.next_icon)}
                onClick={handleNextPage}
              >
                <KeyboardArrowRight />
              </button>
            </Box>
          )}
        </Box>
      )}
      {footerType === '1' && (
        <Box className={styles.footer_box}>
          <Box component="div" className={styles.page_text}>
            共 <span className={styles.cur}>{totalPage}</span> 筆
          </Box>
        </Box>
      )}
      {footerType === '2' && (
        <Box className={cx(styles.footer_box, styles.align_right)}>
          {totalPage > 1 && (
            <Box component="div">
              <button
                className={cx(styles.page_icon, styles.back_icon)}
                onClick={handleBackPage}
              >
                <KeyboardArrowLeft />
              </button>
              <button
                className={cx(styles.page_icon, styles.next_icon)}
                onClick={handleNextPage}
              >
                <KeyboardArrowRight />
              </button>
            </Box>
          )}
        </Box>
      )}
      {footerType === '3' && (
        <Box className={cx(styles.footer_box, styles.align_right)}>
          <Box component="div">
            <button
              className={cx(styles.page_icon, styles.back_icon)}
              onClick={handleBackRange}
            >
              <KeyboardArrowLeft />
            </button>
            <button
              className={cx(styles.page_icon, styles.next_icon)}
              onClick={handleNextRange}
            >
              <KeyboardArrowRight />
            </button>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};

CFooter.prototype = {
  page: PropTypes.number,
  totalPage: PropTypes.number,
  setPage: PropTypes.func,
  footerType: PropTypes.string,
};

export default CFooter;
