import config from '../config';
//Sweetalert2
import {SwalWithCustomButtons} from '../vendors/customSwal';
import AuthService from '../services/auth';
import homePath from '../utils/homePath'

// 新增成功	CreateSuccess = 101		httpcode=200
// 新增失敗	CreateFail = 102		httpcode=400

// 編輯成功	EditSuccess = 201		httpcode=200
// 編輯失敗	EditFail = 202			httpcode=400

// 刪除成功	DeleteSuccess = 301		httpcode=200
// 刪除失敗	DeleteFail = 302		httpcode=400

// 登入失敗	NoTargetId = 401		httpcode=401
// 無授權資料	NoFoundTargetData = 402	httpcode=402

// 查詢成功	GetDataSuccess = 501	httpcode=200
// 查詢失敗	GetDataFail = 502		httpcode=400
// 查詢無資料	DataNotFound = 503	httpcode=200

// 例外事件	Exception = 9999		httpcode=500

const Auth = new AuthService();

const errorStatus = (response) => {    
    if (response === undefined || response === null){
        return SwalWithCustomButtons.fire({title:'Oops..',text:'伺服器異常' ,icon:'error'}).then(()=>{
            Auth.logout();
            const url = homePath.defaultPathUrl() + '/500'
            document.location.href=url; 
        });
    }else{
        const httpcode = response.status;
        let errorMessage = '';
        let devErrorMessage = '';

        response.json().then(data => {
            if(config.if_dev){
                devErrorMessage = data.RC + '-' + data.RM;
            }

            if(httpcode === 400){     

                if(data.Result.length > 0 ){
                    for(let i = 0;i < data.Result.length ; i++){
                        errorMessage += data.Result[i];                    
                    }                
                    
                    return SwalWithCustomButtons.fire({title:'Oops..無法進行此操作' ,text:errorMessage+ devErrorMessage ,icon:'error'});
                    
                }else{
                    return SwalWithCustomButtons.fire({title:'Oops..無法進行此操作' ,text: devErrorMessage,  icon:'error'});
                }                       
        
            }else if(httpcode === 500){
        
                return SwalWithCustomButtons.fire({title:'Oops..發生錯誤' , text: devErrorMessage, icon:'error'});
        
            }else if (httpcode === 401){
        
                return SwalWithCustomButtons.fire({title:'Oops..',text:'驗証已過期，請重新登入' + devErrorMessage ,icon:'error'}).then(()=>{
                    Auth.logout();
                    const url = homePath.defaultPathUrl()
                    document.location.href=url; 
                });
        
            }else if (httpcode === 403){
        
                return SwalWithCustomButtons.fire({title:'Oops..',text:'很抱歉，您尚無權限' + devErrorMessage ,icon:'error'}).then(()=>{           
                    const url = homePath.defaultPathUrl()
                    document.location.href=url; 
                });
            }
            else if (httpcode === 404){
        
            }
            else{
                return SwalWithCustomButtons.fire({title:'Oops..',text:'伺服器異常' + devErrorMessage ,icon:'error'}).then(()=>{
                    Auth.logout();
                    const url = homePath.defaultPathUrl()
                    document.location.href=url; 
                });
            }

        })
    }
};



export default {
    errorStatus    
};