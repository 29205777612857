import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
//Material-UI
import { Box, Fab } from '@mui/material';
import { PersonAdd } from '@mui/icons-material';
//custom css
import styles from './styles.module.scss';
//js
import homePath from '../../../utils/homePath';

const CHeader = ({ titleName, imgPath, headerType = '0', drpOrg, btn }) => {
  //headerType: 0:normal, 1:audit, 2: org, 3:org_with_uplv, 4:memberList

  const handleMemberAddClick = () => {
    const path = homePath.PRMPathUrl('member_add');
    window.location.href = path;
  };

  return (
    <Box className={styles.header}>
      {headerType !== '4' && (
        <React.Fragment>
          <Box className={styles.title_box}>
            <Box component="div" className={styles.icon_bk}>
              {imgPath}
            </Box>
            <Box component="div" className={styles.title}>
              {titleName}
            </Box>
          </Box>
          {headerType === '1' && <Box className={styles.btn_box}>{btn}</Box>}
          {headerType === '2' && (
            <Box className={cx(styles.btn_box)}>{drpOrg}</Box>
          )}
          {headerType === '3' && (
            <Box className={cx(styles.org_list_box)}>{drpOrg}</Box>
          )}
        </React.Fragment>
      )}
      {headerType === '4' && (
        <React.Fragment>
          <Box className={styles.title_box}>
            <Box component="div" className={styles.icon_bk}>
              {imgPath}
            </Box>
            <Box component="div" className={styles.title}>
              <Box component="span">{titleName}</Box>
              {/* <Tooltip title='新增成員' placement="right" size="small">
                            <IconButton className={styles.icon_bk_add} component="span" onClick={handleMemberAddClick} >
                                <PersonAdd/>
                                <Box component="span" className={styles.text_normal}>新增</Box>
                            </IconButton>
                        </Tooltip>*/}
              <Fab
                variant="extended"
                size="small"
                className={styles.icon_bk_add}
                onClick={handleMemberAddClick}
              >
                <PersonAdd />
                <Box
                  component="span"
                  className={cx(styles.text_normal, styles.none_text)}
                >
                  新增
                </Box>
              </Fab>
            </Box>
          </Box>
          <Box className={cx(styles.org_list_box)}>{drpOrg}</Box>
        </React.Fragment>
      )}
    </Box>
  );
};

CHeader.prototype = {
  titleName: PropTypes.string,
  imgPath: PropTypes.element,
  headerType: PropTypes.string,
  drpOrg: PropTypes.element,
  btn: PropTypes.element,
};

export default CHeader;
