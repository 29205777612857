import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
//Material-UI
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
//custom css
import styles from './styles.module.scss';

const NewsItem = ({ newsContent }) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  let tagType = {};
  switch (newsContent.website_group_display_items[0].website_group_aid) {
    case 10:
      tagType = styles.prm;
      break;
    case 40:
      tagType = styles.ins;
      break;
    case '火把大學':
      tagType = styles.tu;
      break;
    default:
      tagType = styles.prm;
      break;
  }

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleClick = () => {
    window.open(newsContent.news_link, '_blank');
  };

  return (
    <React.Fragment>
      <Box
        className={styles.item_layout}
        onClick={newsContent.link_type === 1 ? handleOpen : handleClick}
      >
        {/* 等於1的是內部開啟 */}
        <Box className={styles.top}>
          <Box className={cx(styles.tag, tagType)}>
            {newsContent.website_group_display_items[0].website_group_name}
          </Box>
          <Box className={styles.datetime}>{newsContent.news_event_date}</Box>
        </Box>
        <Box className={cx(styles.news_title, styles.ellipsis)}>
          {newsContent.news_subject}
        </Box>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialog}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span className={cx(styles.dialog_title, tagType)}>
            {newsContent.news_subject}
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <div dangerouslySetInnerHTML={{ __html: newsContent.news_content }} />
          {newsContent.referens_data.length > 0 && (
            <Box className={styles.news_ref}>
              <Box>其他參考資料：</Box>
              {newsContent.referens_data.map((item, index) => (
                <a
                  key={index}
                  href={item.ref_link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.ref_desc}
                </a>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="outlined" onClick={handleClose}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

NewsItem.prototype = {
  newsContent: PropTypes.shape({
    news_aid: PropTypes.number,
    news_subject: PropTypes.string,
    news_event_date: PropTypes.string,
    link_type: PropTypes.number,
    news_link: PropTypes.string,
    website_group_display_items: PropTypes.array,
    news_content: PropTypes.string,
    referens_data: PropTypes.object,
  }),
};

export default NewsItem;
