import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
//Material-UI
import { withStyles } from '@mui/styles';
import {
  Box,
  Menu,
  MenuItem,
  Link,
  Divider,
  Tooltip,
  Badge,
  Avatar,
} from '@mui/material';

import {
  MoreHoriz,
  CalendarToday,
  EventAvailable,
  PersonAddDisabled,
} from '@mui/icons-material';

// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
// import EventAvailableIcon from '@material-ui/icons/EventAvailable';
// import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';

//custom css
import styles from './styles.module.scss';
//custom js
import homePath from '../../../utils/homePath';

const contentFormat = (inputObj) => {
  const outputObj = {};
  Object.keys(inputObj).forEach((key) => {
    switch (key) {
      case 'account_name':
        outputObj.accountName = inputObj[key];
        break;
      case 'account_aid':
        outputObj.accountAid = inputObj[key];
        break;
      case 'member_aid':
        outputObj.memberAid = inputObj[key];
        break;
      case 'sq_state':
        outputObj.sqState = inputObj[key];
        break;
      case 'group_privilege_name':
        outputObj.groupPrivilegeName = inputObj[key];
        break;
      case 'if_member_link_account_in_org':
        outputObj.ifMemberInOrg = inputObj[key];
        break;
      default:
        outputObj[key] = inputObj[key];
    }
  });
  return outputObj;
};

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

const Accountitem = ({ accountContent, orgAid }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const accountInfo = contentFormat(accountContent);
  const groupPrivilegeList = accountInfo.groupPrivilegeName.join();

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreClose = (event) => {
    setAnchorEl(null);
  };

  const handleOutgoingLeaderClick = () => {
    const path = homePath.PRMPathUrl('outgoing_leader');
    localStorage.setItem('AID', accountInfo.accountAid);
    localStorage.setItem('OID', orgAid);
    localStorage.setItem('ref_com', 'accountManagerRef');
    handleMoreClose();
    document.location.href = path;
  };

  const hanldeMemberSQClick = () => {
    const path = homePath.PRMPathUrl('member_survey/add');
    localStorage.setItem('MID', accountInfo.memberAid);
    localStorage.setItem('ref_com', 'memberListRef');
    handleMoreClose();
    document.location.href = path;
  };

  return (
    <Box className={cx(styles.item_layout, styles.prm)}>
      <Box className={styles.front}>
        <Box className={cx(styles.state)}>
          {/* <AvatarGroup max={2}>
                        <Tooltip title={accountInfo.sqState !== 1 ? "已有屬靈健檢表" :"半年內未填寫屬靈健檢表"}>
                            <Avatar className={accountInfo.sqState !== 1 ? styles.done: null}>
                                {accountInfo.sqState === 1 && <CalendarTodayIcon />}
                                {accountInfo.sqState !== 1 && <EventAvailableIcon />}
                            </Avatar>
                        </Tooltip>
                        <Tooltip title={"成員不在此小組"}>
                            <Avatar className={styles.done}>
                                <PersonAddDisabledIcon />
                            </Avatar>
                        </Tooltip>
                    </AvatarGroup> */}
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            invisible={accountInfo.ifMemberInOrg}
            badgeContent={
              <Tooltip title={'成員不在此小組'}>
                <SmallAvatar className={styles.warning}>
                  <PersonAddDisabled className={styles.member_in_org_icon} />
                </SmallAvatar>
              </Tooltip>
            }
          >
            <Tooltip
              title={
                accountInfo.sqState !== 1
                  ? '已有屬靈健檢表'
                  : '半年內未填寫屬靈健檢表'
              }
            >
              <Avatar
                className={accountInfo.sqState !== 1 ? styles.done : null}
              >
                {accountInfo.sqState === 1 && <CalendarToday />}
                {accountInfo.sqState !== 1 && <EventAvailable />}
              </Avatar>
            </Tooltip>
          </Badge>

          {/* <FiveAIcon iconType={accountInfo.sqState || ''} /> */}
        </Box>
        <Box className={styles.info}>
          <Tooltip title={groupPrivilegeList}>
            <Box className={cx(styles.role, styles.ellipsis)}>
              {accountInfo.groupPrivilegeName.map((item, index) => (
                <Box key={index} pr={1} component="span">
                  {item}
                </Box>
              ))}
              {/* {accountInfo.group_privilege_name[0]} */}
            </Box>
          </Tooltip>

          <Box className={cx(styles.name, styles.ellipsis)}>
            {accountInfo.accountName}
          </Box>
        </Box>
      </Box>

      <Box className={cx(styles.back)}>
        <Link
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleMoreClick}
        >
          {/* <Link aria-controls="simple-menu" aria-haspopup="true" > */}
          <MoreHoriz />
        </Link>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMoreClose}
          PaperProps={{
            style: {
              backgroundColor: '#6496db',
              color: '#ffffff',
              // color: '#cbcbcb',
            },
          }}
          MenuListProps={{
            style: {
              paddingTop: '0px',
              paddingBottom: '0px',
            },
          }}
        >
          {/* <MenuItem style={{color: '#cbcbcb'}}>卸任(未開放)</MenuItem>  */}
          <MenuItem onClick={handleOutgoingLeaderClick}>卸任</MenuItem>
          <Divider style={{ backgroundColor: '#b7ceed' }} />
          {/* <MenuItem style={{color: '#cbcbcb'}}>填寫健檢表(未開放)</MenuItem> */}
          <MenuItem onClick={hanldeMemberSQClick}>填寫健檢表</MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

Accountitem.propTypes = {
  accountContent: PropTypes.shape({
    group_privilege_name: PropTypes.array,
    account_name: PropTypes.string,
    sq_state: PropTypes.number,
    account_aid: PropTypes.number,
    member_aid: PropTypes.number,
    if_member_link_account_in_org: PropTypes.bool,
  }),
  orgAid: PropTypes.number,
};

export default Accountitem;
