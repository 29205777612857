import React from 'react';

//custom css
import styles from './styles.module.scss';

const LoadingSpinner = () => {


    return(
        <div className={styles.overlay}>                    
            <div className={styles.spinner}>
                <div className={styles.rect1}></div>
                <div className={styles.rect2}></div>
                <div className={styles.rect3}></div>
                <div className={styles.rect4}></div>
                <div className={styles.rect5}></div>
            </div>
        </div>
    )
}

export default LoadingSpinner;