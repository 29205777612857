import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
//Material-UI
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowDropDown } from '@mui/icons-material';

//custom css
import styles from './styles.module.scss';

//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#87b1eb',
    color: '#ffffff',
    borderRadius: '18.5px',
    minWidth: '120px',
    '&:hover': {
      backgroundColor: '#87b1eb',
    },
  },
}));

const DrpOrg = ({
  orgType,
  orgList,
  setOrgList,
  curOrgAid,
  setCurOrgAid,
  refName,
}) => {
  //orgType: '1':org, '2': org with uplv, '3': no other
  const [anchorEl, setAnchorEl] = useState(null);
  const [curOrgInfo, setCurOrgInfo] = useState({});
  const classes = useStyles();

  useEffect(() => {
    let mounted = true;
    //org_privilege_list_url
    apiCaller
      .apiFetch(config.org_privilege_list_url, {
        method: 'POST',
        body: '',
      })
      .then((response) => {
        if (mounted) {
          let org_list_with_uplv_other = response.Result;

          if (orgType !== '3') {
            org_list_with_uplv_other = response.Result.concat([
              {
                org_aid: 0,
                org_name: '其他',
                up_lv: [],
              },
            ]);
          }

          setOrgList(org_list_with_uplv_other);
          //如果session有值，就不帶第一筆
          const boardCardOrgSetting = sessionStorage.getItem(
            'boardCardOrgSetting'
          );
          if (boardCardOrgSetting !== null) {
            const settingData = JSON.parse(boardCardOrgSetting);
            if (settingData.hasOwnProperty(refName)) {
              setCurOrgAid(Number(settingData[refName]));
              setCurOrgInfo(
                org_list_with_uplv_other.find((item) => {
                  return Number(item.org_aid) === Number(settingData[refName]);
                })
              );
            } else {
              setCurOrgAid(org_list_with_uplv_other[0].org_aid);
              setCurOrgInfo(
                org_list_with_uplv_other.find((item) => {
                  return (
                    Number(item.org_aid) ===
                    Number(org_list_with_uplv_other[0].org_aid)
                  );
                })
              );
            }
          } else {
            setCurOrgAid(org_list_with_uplv_other[0].org_aid);
            setCurOrgInfo(
              org_list_with_uplv_other.find((item) => {
                return (
                  Number(item.org_aid) ===
                  Number(org_list_with_uplv_other[0].org_aid)
                );
              })
            );
          }
        }
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
      });

    return () => (mounted = false);
  }, [orgType]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    const selValue = event.currentTarget.value;

    setAnchorEl(null);
    if (selValue !== undefined) {
      setCurOrgAid(selValue);
    }
  };

  const handleOrgChange = (event) => {
    const { value } = event.currentTarget;

    setAnchorEl(null);
    if (value !== undefined) {
      setCurOrgAid(value);
      setCurOrgInfo(
        orgList.find((item) => {
          return Number(item.org_aid) === Number(value);
        })
      );
      handleOrgSession(value, refName);
    }
  };

  const handleOrgSession = (orgAid, objName) => {
    const boardCardOrgSetting = sessionStorage.getItem('boardCardOrgSetting');
    if (boardCardOrgSetting !== null) {
      const prevData = JSON.parse(boardCardOrgSetting);
      prevData[objName] = orgAid;
      sessionStorage.setItem('boardCardOrgSetting', JSON.stringify(prevData));
    } else {
      let newData = new Object();
      newData[objName] = orgAid;
      sessionStorage.setItem('boardCardOrgSetting', JSON.stringify(newData));
    }
  };

  return (
    <Box className={styles.vertical_c}>
      {(orgType === '2' || orgType === '3') && (
        <Box className={styles.uplv}>
          {Object.keys(curOrgInfo).length > 0 &&
            curOrgInfo.up_lv.length > 1 && (
              <Box className={cx(styles.uplv_item, styles.lv1)}>
                {orgList.length > 0 && curOrgInfo.up_lv[1]}
              </Box>
            )}
          {Object.keys(curOrgInfo).length > 0 &&
            curOrgInfo.up_lv.length > 0 && (
              <Box className={cx(styles.uplv_item, styles.lv2)}>
                {orgList.length > 0 && curOrgInfo.up_lv[0]}
              </Box>
            )}
        </Box>
      )}
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.root}
      >
        {orgList.length > 0 &&
          Object.keys(curOrgInfo).length > 0 &&
          curOrgInfo.org_name}
        <ArrowDropDown />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {orgList.length > 0 &&
          orgList.map((item, index) => (
            <MenuItem
              key={index}
              onClick={handleOrgChange}
              selected={curOrgAid === item.org_aid}
              value={item.org_aid}
            >
              {item.org_name}
            </MenuItem>
          ))}
      </Menu>
    </Box>
  );
};

DrpOrg.prototype = {
  orgType: PropTypes.string.isRequired,
  orgList: PropTypes.shape({
    org_aid: PropTypes.number,
    ort_name: PropTypes.string,
    up_lv: PropTypes.array,
  }),
  setOrgList: PropTypes.func.isRequired,
  curOrgAid: PropTypes.number.isRequired,
  setCurOrgAid: PropTypes.func.isRequired,
};

export default DrpOrg;
