import React, { useState, useEffect } from 'react';
//Material-UI
import { Box, Paper } from '@mui/material';
//icon
import LocalCafeIcon from '@mui/icons-material/LocalCafe';

//custom css
import styles from './styles.module.scss';
//components
import CHeader from '../../components/card/cHeader';
import CLoading from '../../components/card/cLoading';
// import CFooter from '../../components/card/cFooter'

//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const web_article_default = {
  article_title: '',
  article_content:
    '以賽亞書30:15 <br/> 你們得救在乎歸回安息；你們得力在乎平靜安穩；',
  article_cover_img_src:
    'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=753&q=80',
  // article_cover_img_src:'https://images.unsplash.com/photo-1469827160215-9d29e96e72f4?ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80'
};

const Article = () => {
  const [info, setInfo] = useState(web_article_default);
  const [circularloading, setCircularLoading] = useState(false);

  useEffect(() => {
    setCircularLoading(true);
    //web_article_info_url
    apiCaller
      .apiFetch(config.web_article_info_url, {
        method: 'POST',
        body: '',
      })
      .then((response) => {
        if (response.Result.length > 0) {
          setInfo(response.Result[0]);
        }
        setCircularLoading(false);
      })
      .catch((err) => {
        setCircularLoading(false);
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  }, []);

  return (
    <Paper className={styles.card}>
      <CHeader titleName="休息一下" imgPath={<LocalCafeIcon />} />
      <div className={styles.card_content}>
        {circularloading && <CLoading />}
        {/* {!circularloading && Object.keys(info).length === 0 &&
                    <NoData />
                } */}
        {!circularloading && Object.keys(info).length > 0 && (
          <React.Fragment>
            <Box className={styles.photo_box}>
              <img
                src={info.article_cover_img_src}
                className={styles.cover}
                alt=""
              ></img>
            </Box>
            <Box className={styles.article}>
              <Box className={styles.article_title}>{info.article_title}</Box>
              <Box className={styles.article_content}>
                <div
                  dangerouslySetInnerHTML={{ __html: info.article_content }}
                />
              </Box>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Paper>
  );
};

export default Article;
