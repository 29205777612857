const dateFormat = inputDate =>{
    let date = new Date(inputDate);
    let outputDate = ''
    const appendLeadingZeroes = n =>{
        if(n <= 9){
            return "0" + n;
          }
          return n
    };    
    outputDate = date.getFullYear() + "-" + appendLeadingZeroes(date.getMonth() + 1) + "-" + appendLeadingZeroes(date.getDate());
    return outputDate;
}

const dateWeekFormat =inputDate=>{
    let curdate = new Date(inputDate);
    let weeks = curdate.getDay();
    let str_week = '';
    switch(weeks){
        case 0:
            str_week = '日';
            break;
        case 1:
            str_week = '一';
            break;
        case 2:
            str_week = '二';
            break;
        case 3:
            str_week = '三';
            break;
        case 4:
            str_week = '四';
            break;
        case 5:
            str_week = '五';
            break;
        case 6:
            str_week = '六';
            break;
        default:
            str_week = '';
            break;
    }

    return str_week;

}

export default {
    dateFormat,
    dateWeekFormat,
  };