import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Redirect } from 'react-router-dom';
import cx from 'classnames';

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  FormControl,
  Collapse,
} from '@mui/material';

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import ClearIcon from '@mui/icons-material/Clear';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import CreateIcon from '@mui/icons-material/Create';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NewspaperOutlinedIcon from '@mui/icons-material/NewspaperOutlined';

//Sweetalert2
import Swal from 'sweetalert2';
import { SwalWithCustomButtons } from '../../vendors/customSwal';

//custom css
import styles from './styles.module.scss';

import AuthService from '../../services/auth';
import homePath from '../../utils/homePath';
import LoadingSpinner from '../../components/LoadingSpinner';
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const Auth = new AuthService();

const Random = {
  _pattern: /[a-zA-Z0-9_\-\+\.]/,
  _getRandomByte: function () {
    if (window.crypto && window.crypto.getRandomValues) {
      var result = new Uint8Array(1);
      window.crypto.getRandomValues(result);
      return result[0];
    } else if (window.msCrypto && window.msCrypto.getRandomValues) {
      var result = new Uint8Array(1);
      window.msCrypto.getRandomValues(result);
      return result[0];
    } else {
      return Math.floor(Math.random() * 256);
    }
  },

  generate: function (length) {
    return Array.apply(null, { length: length })
      .map(function () {
        var result;
        while (true) {
          result = String.fromCharCode(this._getRandomByte());
          if (this._pattern.test(result)) {
            return result;
          }
        }
      }, this)
      .join('');
  },
};

const PasswordFG = ({ open, handleClose, clearLoginTimes }) => {
  const [userInfo, setUserInfo] = useState({ userid: '', email: '' });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleInfoChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setUserInfo({ ...userInfo, [key]: value });
  };

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    //api acfp
    apiCaller
      .apiFetch(config.acfp_url, {
        method: 'POST',
        body: JSON.stringify(userInfo),
      })
      .then((response) => {
        clearLoginTimes();
        setIsLoading(false);
        handleClose();
        SwalWithCustomButtons.fire({ title: '請至信箱確認', icon: 'success' });
      })
      .catch((err) => {
        setIsLoading(false);
        handleClose();
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="sm"
      fullWidth={true}
    >
      {isLoading && <LoadingSpinner />}
      <DialogTitle id="form-dialog-title" className={styles.dialog_title}>
        忘記密碼
      </DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          {/* <DialogContentText>
            請輸入你的帳號及信箱
          </DialogContentText> */}
          <FormControl fullWidth margin="normal">
            <TextField
              name="userid"
              label="帳號"
              value={userInfo.userid}
              InputLabelProps={{
                shrink: true,
              }}
              required
              className={styles.align_left}
              onChange={handleInfoChange}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              name="email"
              label="信箱"
              value={userInfo.email}
              type="email"
              InputLabelProps={{
                shrink: true,
              }}
              required
              className={styles.align_left}
              onChange={handleInfoChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            取消
          </Button>
          <Button type="submit" color="primary">
            確認
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const Login = () => {
  const navigate = useNavigate();
  const [userid, setUserid] = useState('');
  const [password, setPassword] = useState('');
  const [keepAlive, setKeepAlive] = useState(0);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkLoginTimes = () => {
    const loginFail = sessionStorage.getItem('trylog' + userid);
    //如果是空的，就新增一個紀錄
    if (!loginFail) {
      sessionStorage.setItem(
        'trylog' + userid,
        JSON.stringify({ id: userid, count: 0 })
      );
      return true;
    } else {
      const loginFailData = JSON.parse(loginFail);
      const tryLogCount = Number(loginFailData.count) + 1;
      //現在輸入的帳號，次數是否大於3次
      if (tryLogCount === 3) {
        var thirtyMinutesLater = new Date();
        thirtyMinutesLater.setMinutes(thirtyMinutesLater.getMinutes() + 30);
        sessionStorage.setItem(
          'trylog' + userid,
          JSON.stringify({
            id: userid,
            count: tryLogCount,
            timestamp: Math.round(thirtyMinutesLater / 1000),
          })
        );
        return false;
      } else if (tryLogCount > 3) {
        if (loginFailData.hasOwnProperty('timestamp')) {
          const curTimestamp = Math.round(new Date() / 1000);
          if (loginFailData.timestamp < curTimestamp) {
            sessionStorage.removeItem('trylog' + userid);
            return true;
          }
        }
        return false;
      } else {
        sessionStorage.setItem(
          'trylog' + userid,
          JSON.stringify({ id: userid, count: tryLogCount })
        );
        return true;
      }
    }
  };

  const clearLoginTimes = () => {
    const loginFail = sessionStorage.getItem('trylog' + userid);
    if (loginFail) {
      sessionStorage.removeItem('trylog' + userid);
    }
  };

  const sso = (jtiValue) => {
    // console.log(jtiValue);
    let xhr = new XMLHttpRequest();
    let url = `https://portal.torchchurch.com/PrmManagement/security_control_sso.aspx?jti=${jtiValue}&rndkey=${Random.generate(
      16
    )}`;
    console.log(url);
    xhr.open('GET', url);
    // xhr.open('POST', 'https://portal.torchchurch.com/PrmManagement/security_control_sso.aspx');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.setRequestHeader(
      'X-Acts29-Signature',
      'fPUHaR!v=?ZdH25MtsH+2UgdAbmcw.5-'
    );
    // xhr.send(JSON.stringify({jti: jtiValue, rndkey:Random.generate(16)}));
    xhr.send();
  };

  const sso_url = (access_id) => {
    let url = '';
    if (access_id !== null) {
      url = `https://portal.torchchurch.com/InternalSys/security_control_sso.aspx?acttp=c&access_id=${access_id}&rnd=${Random.generate(
        16
      )}`;
    }
    return url;
  };

  const handleFormSubmit = (e) => {
    if (!open) {
      setLoading(true);
      e.preventDefault();
      if (checkLoginTimes()) {
        Auth.login(userid, password, keepAlive)
          .then((res) => {
            clearLoginTimes();
            sessionStorage.setItem('welcome', true);
            if (config.env !== 'dev') {
              const url = sso_url(Auth.getProfile().access_id);
              //console.log(url);
              // setLoading(false);
              document.location.href = url;
            } else {
              setLoading(false);
              navigate('/app1/dashboard');
            }
          })
          .catch((err) => {
            setPassword('');
            setLoading(false);
            //Auth.logout();
            const loginFail = sessionStorage.getItem('trylog' + userid);
            const loginFailData = JSON.parse(loginFail);
            let errorMessage = '';
            if (loginFail && loginFailData.hasOwnProperty('count')) {
              const testTimes = 3 - loginFailData.count;
              errorMessage =
                '還有 <b style="color: #f27474;">' +
                testTimes +
                '</b> 次機會嘗試';
            }
            SwalWithCustomButtons.fire({
              title: '帳號/密碼錯誤',
              html: errorMessage,
              icon: 'error',
              heightAuto: false,
            });
            console.log(err);
          });
      } else {
        setPassword('');
        setLoading(false);
        let difftimeText = '，請稍後再試';
        const loginFail = sessionStorage.getItem('trylog' + userid);
        const loginFailData = JSON.parse(loginFail);
        if (loginFailData.hasOwnProperty('timestamp')) {
          const curTimestamp = Math.round(new Date() / 1000);
          const difftimestamp = loginFailData.timestamp - curTimestamp;
          difftimeText =
            '<b style="color: #f27474;">' +
            Math.floor(difftimestamp / 60) +
            '</b>分鐘後再試';
        }
        Swal.fire({
          title: 'Sorry...嘗試太多次囉！',
          html: '<span>申請忘記密碼或休息一下' + difftimeText + '</span>',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: '忘記密碼',
          cancelButtonText: '取消',
          confirmButtonColor: '#f27474',
          cancelButtonColor: '#e0e0e0',
          heightAuto: false,
        }).then((result) => {
          if (result.value) {
            handleClickOpen();
          }
        });
      }
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleKeepAlive = (e) => {
    if (e.target.checked) {
      setKeepAlive(1);
    } else {
      setKeepAlive(0);
    }
  };

  return (
    <div className={styles.login_layout}>
      <div className={styles.form_container}>
        <form id="form1" onSubmit={handleFormSubmit}>
          <div
            className={cx(
              styles.item_2,
              styles.vertical_c,
              styles.horizontal_right
            )}
          >
            帳號
          </div>
          <div className={styles.item_10}>
            <input
              className={styles.input_text}
              type="text"
              name="userid"
              placeholder="請輸入帳號"
              autoComplete="on"
              value={userid}
              onChange={(e) => setUserid(e.target.value)}
              required
            />
          </div>
          <div
            className={cx(
              styles.item_2,
              styles.vertical_c,
              styles.horizontal_right
            )}
          >
            密碼
          </div>
          <div className={styles.item_10}>
            <input
              className={styles.input_text}
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="請輸入密碼"
              value={password}
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span
              className={styles.show_password_btn}
              onClick={(e) => setShowPassword(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </span>
          </div>
          <div
            className={cx(
              styles.item_12,
              styles.vertical_c,
              styles.horizontal_c,
              styles.py_m
            )}
          >
            <LiveHelpIcon />
            <span
              className={cx(
                styles.pink_text_small,
                styles.mr_s,
                styles.pointer
              )}
              onClick={handleClickOpen}
            >
              忘記密碼
            </span>
            <PasswordFG
              open={open}
              handleClose={handleClose}
              clearLoginTimes={clearLoginTimes}
            />
            <input
              id="keep_alive"
              type="checkbox"
              className={cx(styles.custom_checkbox, styles.white)}
              checked={keepAlive}
              onChange={handleKeepAlive}
            />
            <label
              htmlFor="keep_alive"
              className={cx(styles.pink_text_small, styles.mr_s)}
            >
              記住我(一週內)
            </label>
          </div>
          <div className={cx(styles.item_12, styles.login_button)}>
            <button type="submit" disabled={loading}>
              {loading && (
                <CircularProgress size={28} className={styles.buttonProgress} />
              )}
              <span>登入</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const MenuContentItem = ({ contentDetail }) => {
  const [open, setOpen] = useState(true);

  const handleClickMenu = (link) => {
    window.location.href = link;
    //window.open(link,'_blank');
  };

  const handleHomePageBlock = (blockNameRef) => {
    localStorage.setItem('ref_com', blockNameRef);
    // const url = homePath.homePathUrl();
    // return url
    // document.location.href = url;
  };

  return (
    <React.Fragment>
      <dt>
        {contentDetail.href !== '' && (
          // <span className={styles.pointer} onClick={()=>handleClickMenu(`${contentDetail.href}`)}>{contentDetail.website_name}</span>
          <a href={contentDetail.href}>{contentDetail.website_name}</a>
        )}
        {contentDetail.href === '' && (
          <span
            className={cx(styles.group_website, { [styles.open]: open })}
            onClick={() => setOpen(!open)}
          >
            {contentDetail.website_name}
            {open && contentDetail.website_items.length > 0 && (
              <ExpandLessIcon />
            )}
            {!open && contentDetail.website_items.length > 0 && (
              <ExpandMoreIcon />
            )}
          </span>
        )}
      </dt>
      <Collapse in={open}>
        {contentDetail.website_items.length > 0 &&
          contentDetail.website_items.map((v, k) => (
            <ul key={k} className={styles.menu_c_sub}>
              <dt>
                {/* <span className={styles.pointer} onClick={()=>handleClickMenu(`${v.href}`)}>{v.page_name}</span> */}
                <a href={v.href}>{v.page_name}</a>
              </dt>
            </ul>
          ))}
        {/* {contentDetail.website_name ==="牧養系統" &&
                    <ul className={styles.menu_c_sub}>
                        <dt>
                            <span className={styles.pointer} onClick={()=>handleHomePageBlock('memberListRef')}>成員列表</span>
                        </dt>
                    </ul>
                } */}
      </Collapse>
    </React.Fragment>
  );
};

const Content = ({ setAuthMenu }) => {
  const [profile, setProfile] = useState({});
  const [menuContent, setMenuContent] = useState([]);

  useEffect(() => {
    // localStorage.removeItem("ref_com");
    //account_profile_url
    apiCaller
      .apiFetch(config.account_profile_url, {
        method: 'POST',
        body: '',
      })
      .then((response) => {
        setProfile(response.Result[0]);
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
      });

    //account_privilege_menu_url
    apiCaller
      .apiFetch(config.account_privilege_menu_url, {
        method: 'POST',
        body: '',
      })
      .then((response) => {
        setMenuContent(response.Result);
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  }, []);

  const sso_logout_url = (access_id) => {
    let url = '';
    if (access_id !== null) {
      url = `https://portal.torchchurch.com/InternalSys/security_control_ssor.aspx?acttp=r&access_id=${access_id}&rnd=${Random.generate(
        16
      )}`;
    }

    return url;
  };

  const handleLogout = () => {
    const url = sso_logout_url(Auth.getProfile().access_id);
    Auth.logout();
    setAuthMenu(Auth.isAuthenticated());
    document.location.href = url;
  };

  const handleHomePage = () => {
    // localStorage.setItem("ref_com", "groupNewsRef");
    const url = homePath.homePathUrl();
    return url;
    // document.location.href = url;
  };

  const handlePage = (routerName) => {
    const url = homePath.defaultPathUrl() + routerName;
    return url;
    // document.location.href=url;
  };

  // const handleClickPRM = (name) =>{
  //     // const path = window.location.protocol +'//' + window.location.hostname+ '/PRM/' + name;
  //     const path = homePath.PRMPathUrl(name);
  //     window.location.href = path;
  //     // maybe can add spinner while loading
  //     return null;
  // }

  return (
    <div>
      {/* account info */}
      <div className={styles.form_container}>
        <div
          className={cx(styles.item_12, styles.vertical_c, styles.horizontal_c)}
        >
          <span className={cx(styles.white_text_big, styles.py_m)}>
            {profile.user_name || ''}
          </span>
        </div>
        <div
          className={cx(styles.item_12, styles.vertical_c, styles.horizontal_c)}
        >
          <CreateIcon />
          <a
            className={cx(
              styles.pink_text_small,
              styles.mr_s,
              styles.pointer,
              styles.hover_color
            )}
            href={handlePage('/accountInfoChange')}
          >
            變更資訊
          </a>
          <ExitToAppIcon />
          <span
            className={cx(
              styles.pink_text_small,
              styles.mr_s,
              styles.pointer,
              styles.hover_color
            )}
            onClick={handleLogout}
          >
            登出
          </span>
        </div>
      </div>
      <div
        className={cx(
          styles.form_container,
          styles.hr,
          styles.px_l,
          styles.pink_text_small
        )}
      >
        <div className={cx(styles.item_2, styles.vertical_c, styles.mb_s)}>
          帳號
        </div>
        <div className={cx(styles.item_10, styles.mb_s)}>
          {profile.userid || ''}
        </div>
        <div className={cx(styles.item_2, styles.vertical_c, styles.mb_s)}>
          Email
        </div>
        <div className={cx(styles.item_10, styles.mb_s)}>
          {profile.email || ''}
        </div>
        <div className={cx(styles.item_2, styles.vertical_c, styles.mb_s)}>
          Tel
        </div>
        <div className={cx(styles.item_10, styles.mb_s)}>
          {profile.tel || ''}
        </div>
      </div>
      {/* menu content */}
      <dl className={styles.menu_content}>
        <dt>
          <a href={handleHomePage()}>
            <p className={cx(styles.vertical_c)}>
              <HomeOutlinedIcon className={styles.icons_svg} />
              回首頁
            </p>
          </a>
        </dt>
        <dt>
          {/* <span className={styles.pointer} onClick={()=>handlePage('/torchnews')}>本週報告事項</span> */}
          <a href={handlePage('/torchnews')}>
            <p className={cx(styles.vertical_c)}>
              <NewspaperOutlinedIcon className={styles.icons_svg} />
              本週報告事項
            </p>
          </a>
        </dt>
        {menuContent.length <= 0
          ? null
          : menuContent.map((item, index) => (
              <MenuContentItem key={index} contentDetail={item} />
            ))}
      </dl>
    </div>
  );
};

const Menu = ({ show, setShow }) => {
  const [authMenu, setAuthMenu] = useState(false);
  // console.log('----menu init----')
  useEffect(() => {
    setAuthMenu(Auth.isAuthenticated());
    // console.log('menu effect')
  }, []);

  useEffect(() => {
    // console.log('menu effect show')
    if (show === true) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset'; //unset
    }
  }, [show]);
  return (
    <div>
      {/* {console.log('menu render')} */}
      <div
        onClick={() => setShow(!show)}
        className={cx(styles.menuOpen, { [styles.show]: show })}
      ></div>
      <div className={cx(styles.menu, { [styles.show]: show })}>
        <header className={styles.top}>
          <span className={styles.close}>Close</span>
          <span style={{ cursor: 'pointer' }} onClick={() => setShow(!show)}>
            <ClearIcon fontSize="large" />
          </span>
        </header>
        <div className={styles.photo}>
          <div className={styles.bg}>
            <AccountCircleOutlinedIcon className={styles.icon} />
          </div>
        </div>
        {!authMenu && <Login setAuthMenu={setAuthMenu} />}
        {authMenu && <Content setAuthMenu={setAuthMenu} />}
      </div>
    </div>
  );
};

export default Menu;
