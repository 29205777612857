import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { useParams, useNavigate, Navigate } from 'react-router-dom';
//Material-UI
import {
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';

//custom css
import styles from './styles.module.scss';

//import component
import Header from '../../components/header';
import Footer from '../../components/footer';
import LoadingSpinner from '../../components/LoadingSpinner';
import NewsCard from '../../components/newsCard';
import NewsList from '../../components/newsList';
import { useWindowSize } from '../../utils/useWindowSize';

//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const TopItem = ({ newsContent }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <Typography
        component="p"
        onClick={handleOpen}
        className={styles.top_news_subject}
      >
        {newsContent.news_subject}
      </Typography>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        open={openDialog}
        onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          className={cx(styles.dialog_title)}
        >
          <span>{newsContent.news_subject}</span>
        </DialogTitle>
        <DialogContent dividers className={styles.dialog_content}>
          <div dangerouslySetInnerHTML={{ __html: newsContent.news_content }} />
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <Button variant="outlined" size="small" onClick={handleClose}>
            關閉
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const TorchNews = () => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const initState = {
    view: size.width >= 1200 ? 'quilt' : 'list',
  };
  let { urlRewriteId = 'torchchurch' } = useParams();
  const [newsItem, setNewsItem] = useState([]);
  const [topNewsItem, setTopNewsItem] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState(initState.view);

  useEffect(() => {
    if (urlRewriteId === 'torchty' || urlRewriteId === 'torchchurch') {
      localStorage.setItem('urlRewriteId', urlRewriteId);
      setIsLoading(true);
      //acts29_church_page_website_group_news_url
      apiCaller
        .apiFetch(config.acts29_church_page_website_group_news_url, {
          method: 'POST',
          body: JSON.stringify({ acts29_church_url_rewrite_id: urlRewriteId }),
        })
        .then((response) => {
          // setNewsApi(response.Result);
          setNewsItem(response.Result.filter((i) => Number(i.if_top) === 0));
          setTopNewsItem(response.Result.filter((i) => Number(i.if_top) === 1));
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
          httpStatus.errorStatus(err.response);
        });
    } else {
      //404
      navigate('/404');
    }
  }, [urlRewriteId]);

  if (urlRewriteId !== 'torchty' && urlRewriteId !== 'torchchurch') {
    return <Navigate to={{ pathname: '/torchchurch' }} />;
  }

  const handleChange = (event, nextView) => {
    console.log('nextView', nextView);
    if (nextView !== null) {
      setView(nextView);
    }
  };

  const returnSubTitle = (count) => {
    if (count > 0) {
      return '本週報告事項';
    } else {
      return '本週暫無報告事項';
    }
  };

  const renderToggleButton = (view) => (
    <Box pb={3} display="flex" justifyContent="flex-end" alignItems="center">
      <Box pl={1}>
        <ToggleButtonGroup
          size="small"
          value={view}
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="quilt" aria-label="quilt">
            <ViewQuiltIcon />
          </ToggleButton>
          <ToggleButton value="list" aria-label="list">
            <ViewListIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );

  const renderTopNews = (topNewsItem) => (
    <Box width="100%" my={1} pb={2}>
      <Card style={{ display: 'flex' }}>
        <Box style={{ width: '2%', backgroundColor: '#bb2525' }} />
        <CardContent>
          <Box className={styles.news_title} style={{ textAlign: 'left' }}>
            公告通知
          </Box>
          {topNewsItem.map((item, index) => (
            <TopItem key={index} newsContent={item} />
          ))}
        </CardContent>
      </Card>
    </Box>
  );

  const renderNewsByQuilt = (newsItem) => {
    // const newsItem = [1,2,3,4,5,6,7];
    let rowNum = 0; //一排的欄位寬度
    let rowArr = []; //資訊總共幾排
    let itemCount = 0; //一排幾個資訊
    if (
      newsItem.length === 2 ||
      newsItem.length === 4 ||
      newsItem.length === 5 ||
      newsItem.length === 7
    ) {
      rowNum = 6; //3個一排
      rowArr = [0, 1];
    } else {
      rowNum = 4; //4個一排
      rowArr = [0, 1, 2];
    }

    if (newsItem.length > 7) {
      itemCount = Math.floor(newsItem.length / 3) + 1;
    } else {
      itemCount = Math.floor(newsItem.length / 2) + 1;
      if (newsItem.length % 2 === 0) {
        itemCount = Math.floor(newsItem.length / 2);
      }
    }

    if (newsItem.length % 3 === 0) {
      itemCount = Math.floor(newsItem.length / 3);
    }

    return (
      <Grid container direction="row" spacing={4}>
        {rowArr.map((item, index) => {
          const abc = Number(item);
          return (
            <Grid key={index} item md={rowNum} sm={12}>
              <Grid container direction="column" spacing={4} wrap="nowrap">
                {newsItem
                  .slice(abc * itemCount, abc * itemCount + itemCount)
                  .map((i, j) => (
                    <Grid key={j} item sm={12}>
                      <NewsCard newsContent={i} />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    );
  };
  const renderNewsbyList = (newsItem) => (
    <Box width="100%" my={1} pb={2}>
      <Grid container direction="column" spacing={4} wrap="nowrap">
        {newsItem.map((i, j) => (
          <Grid key={j} item sm={12}>
            <NewsList newsContent={i} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return (
    <div className={styles.page}>
      {isLoading && <LoadingSpinner />}
      <Header />
      <div className={styles.wrapper}>
        <Container maxWidth="xl" className={styles.container}>
          <h1 className={cx(styles.d_title_font, styles.d_title)}>
            火把領袖同工資訊平台
          </h1>
          <h2 className={cx(styles.d_subtitle_font, styles.d_subtitle)}>
            {returnSubTitle(newsItem.length)}
          </h2>
          {newsItem.length > 0 && renderToggleButton(view)}
          {topNewsItem.length > 0 && renderTopNews(topNewsItem)}
          {view === 'quilt' && renderNewsByQuilt(newsItem)}
          {view === 'list' && renderNewsbyList(newsItem)}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default TorchNews;
