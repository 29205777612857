import jwt_decode from 'jwt-decode';
import apiCaller from '../utils/apiCaller';
import config from '../config';

export default class AuthService {
  // Initializing important variables
  forgotPassword = (email) =>
    // Get a token from api server using the fetch api
    apiCaller.apiFetch(config.forgot_url, {
      method: 'POST',
      body: JSON.stringify({ email }),
    });

  login = (userid, password, keep_alive) =>
    // Get a token from api server using the fetch api
    apiCaller
      .apiFetch(config.auth_url, {
        crossDomain: true,
        method: 'POST',
        body: JSON.stringify({
          userid: userid,
          password: password,
          keep_alive: keep_alive,
        }),
      })
      .then((res) => {
        if (res.RC === 501) {
          // Save the token in localStorage
          this.setToken(res.Result);
          // this.setUserAid(res.Result);
          return res;
        } else {
          return res;
        }
      });

  isAuthenticated = () => {
    // Checks if there is a saved token and it's still valid
    const token = this.getToken(); // GEtting token from localstorage
    return !!token && !this.isTokenExpired(token); // handwaiving here
  };

  isTokenExpired = (token) => {
    try {
      const decoded = jwt_decode(token);
      // console.log(decoded);
      // console.log(Date.now() / 1000);
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return true;
      }
      return false;
    } catch (err) {
      return false;
    }
  };

  getLifetime = () => {
    if (localStorage.getItem('id_token')) {
      const decoded = jwt_decode(localStorage.getItem('id_token'));
      if (decoded.exp < Date.now() / 1000) {
        // Checking if token is expired. N
        return 0;
      }
      // console.log(decoded.exp);
      // console.log(Math.floor(Date.now() / 1000));
      return decoded.exp - Math.floor(Date.now() / 1000);
    }
    return null;
  };

  setToken = (idToken) => {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken);
  };

  getToken = () =>
    // Retrieves the user token from localStorage
    localStorage.getItem('id_token');

  updateToken = () =>
    apiCaller
      .apiFetch(config.reauth_url, {
        // crossDomain:true,
        method: 'POST',
        Authorization: `Bearer ${this.getToken()}`,
        body: '',
      })
      .then((res) => {
        // Save the token in localStorage
        this.setToken(res.Result);
        return res ? true : false;
      })
      .catch((err) => {});

  // setUserAid = (idToken) =>{
  //     // Using jwt-decode npm package to decode the token
  //     let profile = jwt_decode(idToken);
  //     localStorage.setItem('user_aid', profile[0].userid);
  //   }

  // getUserAid = () =>
  //   // Retrieves the user aid from localStorage
  //   localStorage.getItem('user_aid');

  getProfile = () => {
    // Using jwt-decode npm package to decode the token
    const token = this.getToken();
    let profile = null;
    if (token) {
      profile = jwt_decode(token);
    }
    return profile;
  };

  logout = () => {
    // Clear user token and profile data from localStorage
    localStorage.removeItem(`id_token`);
    localStorage.clear();
    sessionStorage.clear();
  };
}
