import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './vendors/customTheme';

import PrivateRoute from './components/privateRoute';
import Dashboard from './page/dashboard';
import AccountInfoChange from './page/accountInfoChange';
import AccountFP from './page/accountfp';
import TorchNews from './page/torchNews';
import BookingHistory from './page/bookingHistory';
import NotFound from './page/notFound';
import ErrorPage from './page/error';
import Logout from './page/logout';
import News from './page/news';

const dashboardPage = (props) => <Dashboard {...props} />;
const torchNewsPage = (props) => <TorchNews {...props} />;
const accountInfoChangePage = (props) => <AccountInfoChange {...props} />;
const accountfpPage = (props) => <AccountFP {...props} />;
const bookingHistoryPage = (props) => <BookingHistory {...props} />;
const NewsPage = (props) => <News {...props} />;

const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/InternalSys">
          <Route path="torchchurch" Component={torchNewsPage} />
          <Route path="torchnews/:urlRewriteId?" Component={torchNewsPage} />
          {/* <Route exact path="/news/:urlRewriteId?" Component={NewsPage} /> */}
          <Route element={<PrivateRoute />}>
            <Route path="dashboard/:urlRewriteId?" Component={dashboardPage} />
            <Route path="accountinfochange" Component={accountInfoChangePage} />
            <Route path="accountfp" Component={accountfpPage} />
            <Route path="bookinghistory" Component={bookingHistoryPage} />
          </Route>
          <Route exact path="ttorchnews" Component={Logout} />
          <Route exact path="404" Component={NotFound} />
          <Route exact path="500" Component={ErrorPage} />
          <Route exact path=":urlRewriteId?" Component={torchNewsPage} />
        </Route>
        <Route path="404" Component={NotFound} />
        <Route path="500" Component={ErrorPage} />
        <Route path=":urlRewriteId?" Component={torchNewsPage} />
        <Route Component={NotFound} />
      </Routes>
    </Router>
  </ThemeProvider>
);

export default App;
