import { createTheme } from '@mui/material';
import { blue, pink, red } from '@mui/material/colors';

export const theme = createTheme({
  palette: {
    primary: blue,
    secondary: pink,
    error: red,
  },
});
