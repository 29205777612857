import React, { useState, useEffect } from 'react';
import cx from 'classnames';
//heightchart
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
//Material-UI
import { Box, Paper } from '@mui/material';

import ShowChartIcon from '@mui/icons-material/ShowChart';
//components
import CHeader from '../../components/card/cHeader';
import CFooter from '../../components/card/cFooter';
import DrpOrg from '../../components/drpOrg';
import NoData from '../../components/card/noData';
import CLoading from '../../components/card/cLoading';
//custom css
import styles from './styles.module.scss';

//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const convertArray = (arr) => {
  if (arr.length > 0) {
    const newArry = arr.map((item) => {
      item.data = item.data.map((x) => {
        if (isNaN(x)) {
          return x;
        } else {
          return parseInt(x);
        }
      });
      return item;
    });
    return newArry;
  } else {
    return arr;
  }
};

const LineChart = ({ dateRange, seriesData = {}, xaxisColumn = [] }) => {
  const chartTitle = dateRange;

  const options = {
    chart: {
      type: 'line',
      height: 350,
    },
    title: {
      text: chartTitle,
      style: {
        color: '#ff9f23',
        fontSize: '28px',
      },
      align: 'left',
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      x: -10,
      y: -10,
      floating: true,
    },
    xAxis: {
      title: {
        text: '時間區間',
      },
      categories: xaxisColumn,
    },
    yAxis: {
      title: {
        text: '回報人數',
      },
    },
    tooltip: {
      crosshairs: true,
      shared: true,
      enabled: true,
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    colors: ['#62d6b1', '#ff9f23', '#7c9eec'], //colors:['#ff9f23','#7c9eec','#62d6b1'],
    series: seriesData,
    responsive: {
      rules: [
        {
          // 在圖表小於 300px 的情况下，執行響應內容
          condition: {
            // 響應條件
            maxWidth: 400,
          },
          chartOptions: {
            // 響應内容
            chart: {
              height: 380,
            },
            title: {
              style: {
                color: '#ff9f23',
                fontSize: '18px',
              },
              align: 'center',
            },
            legend: {
              align: 'center',
              verticalAlign: 'top',
              floating: false,
            },
          },
        },
      ],
    },
  };

  const callback = (chart) => {
    chart.reflow();
  };

  return (
    <Box>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={callback}
      />
    </Box>
  );
};

const MeetingReportLine = () => {
  const [reportData, setReportData] = useState({});
  const [orgList, setOrgList] = useState([]);
  const [curOrgAid, setCurOrgAid] = useState(0);
  const [circularloading, setCircularLoading] = useState(false);
  const [weekRange, setWeekRange] = useState(-1);

  useEffect(() => {
    setCircularLoading(true);
    if (curOrgAid !== 0) {
      //prm_meeting_report_data2_url
      const apiBody = {
        org_aid: Number(curOrgAid),
        week_range: Number(weekRange),
      };
      apiCaller
        .apiFetch(config.prm_meeting_report_data2_url, {
          method: 'POST',
          body: JSON.stringify(apiBody),
        })
        .then((response) => {
          let result = response.Result;
          result.series_data = convertArray(result.series_data);
          setReportData(result);
          setCircularLoading(false);
        })
        .catch((err) => {
          setCircularLoading(false);
          console.log(err);
          httpStatus.errorStatus(err.response);
        });
    }
  }, [curOrgAid, weekRange]);

  return (
    <Paper className={styles.card}>
      <CHeader
        titleName="回報/穩定人數"
        imgPath={<ShowChartIcon />}
        headerType="3"
        drpOrg={
          <DrpOrg
            orgType="3"
            orgList={orgList}
            setOrgList={setOrgList}
            curOrgAid={curOrgAid}
            setCurOrgAid={setCurOrgAid}
            refName="meetingReportLine"
          />
        }
      />
      <div className={styles.card_content}>
        {circularloading && (
          <Box className={styles.f_all}>
            <CLoading />
          </Box>
        )}
        {!circularloading && Object.keys(reportData).length === 0 && (
          <Box className={styles.f_all}>
            <NoData />
          </Box>
        )}
        {!circularloading && Object.keys(reportData).length > 0 && (
          <React.Fragment>
            <Box className={styles.f_left}>
              <LineChart
                dateRange={reportData.date_range}
                seriesData={reportData.series_data}
                xaxisColumn={reportData.xaxis_column}
              />
            </Box>
            <Box className={styles.f_right}>
              <Box className={styles.info_box}>
                <Box className={styles.text}>{reportData.date_range}</Box>
                <Box className={styles.text}>期間內的主日/小組穩定人數</Box>
                <Box className={styles.content_box}>
                  <Box className={cx(styles.c_box, styles.box_left)}>
                    <Box>主日人數</Box>
                    <Box>
                      <Box component="span" className={styles.count}>
                        {reportData.data_average1}
                      </Box>
                      人
                    </Box>
                  </Box>
                  <Box className={cx(styles.c_box, styles.box_right)}>
                    <Box>小組人數</Box>
                    <Box>
                      <Box component="span" className={styles.count}>
                        {reportData.data_average2}
                      </Box>
                      人
                    </Box>
                  </Box>
                </Box>
                <Box className={styles.note}></Box>
              </Box>
            </Box>
          </React.Fragment>
        )}
      </div>
      <CFooter
        page={weekRange}
        setPage={setWeekRange}
        totalPage={weekRange}
        footerType="3"
      />
    </Paper>
  );
};

export default MeetingReportLine;
