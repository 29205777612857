import React, { useState, useEffect } from 'react';
import cx from 'classnames';
//heightchart
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
//Material-UI
import {
  Box,
  Grid,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

//components
import CHeader from '../../components/card/cHeader';
import NoData from '../../components/card//noData';
import CLoading from '../../components/card/cLoading';

//custom css
import styles from './styles.module.scss';

//js
import homePath from '../../utils/homePath';

//img
import result_status_1 from '../../images/TorchMan-14.png';
import result_status_2 from '../../images/TorchMan-09.png';
import result_status_3 from '../../images/TorchMan-23.png';

//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const PieChart = ({ seriesData = [], result_status = 0 }) => {
  const formatSeriesData =
    seriesData.length > 0 &&
    seriesData.map((elm) => ({ name: elm.name, y: elm.data }));
  const CompletePC =
    seriesData.length > 0
      ? Number(seriesData[0].data) + Number(seriesData[1].data)
      : 0;
  let charText = '';
  if (result_status === 0) {
    charText =
      '<span style="color: #ff7e3f; font-size:30px; ">' +
      CompletePC +
      '</span><span style="color: #ff7e3f; font-size:18px; ">%</span><br><span style="color: #888888; font-size:18px; padding-left:5px;">/100</span>';
  } else {
    let statusImg;
    switch (result_status) {
      case 1:
        statusImg = result_status_1;
        break;
      case 2:
        statusImg = result_status_2;
        break;
      case 3:
        statusImg = result_status_3;
        break;
      default:
        break;
    }
    charText =
      '<img class="' +
      styles.pie_img +
      '" alt="Under development" src="' +
      statusImg +
      '"></img>';
  }

  const options = {
    chart: {
      type: 'pie',
      height: 210,
    },
    title: {
      text: charText,
      align: 'center',
      verticalAlign: 'middle',
      useHTML: true,
    },
    plotOptions: {
      pie: {
        shadow: false,
      },
    },
    tooltip: {
      formatter: function () {
        return '<b>' + this.point.name + '</b>: ' + this.y + ' %';
      },
    },
    series: [
      {
        name: 'Browsers',
        data: formatSeriesData,
        size: '120%',
        innerSize: '65%',
        showInLegend: false,
        dataLabels: {
          enabled: false,
        },
        colors: ['#50d6a5', '#ffc961', '#cdcdcd'],
        // colors:['#ffc961','#50d6a5','#fff']
      },
    ],
    responsive: {
      rules: [
        {
          // 在圖表小於 500px 的情况下，執行響應內容
          condition: {
            // 響應條件
            maxWidth: 300,
          },
          chartOptions: {
            // 響應内容
            chart: {
              height: 200,
            },
          },
        },
      ],
    },
  };

  const callback = (chart) => {
    chart.reflow();
  };

  return (
    <Box>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        callback={callback}
      />
    </Box>
  );
};

const returnResultText = (status) => {
  let statusText = '';
  switch (status) {
    case 1:
      statusText = '恭喜你100%完成';
      break;
    case 2:
      statusText = '下次請準時回報喔';
      break;
    case 3:
      statusText = '加油下次繼續努力';
      break;
    default:
      statusText = '';
      break;
  }

  return statusText;
};

const ReportButton = ({ status }) => {
  let btnStyle = '';
  let btnText = '';
  switch (status) {
    case 0:
      btnStyle = styles.complete;
      btnText = '檢視回報列表';
      break;
    case 1:
      btnStyle = styles.complete;
      btnText = '本週已回報';
      break;
    case 2:
      btnStyle = styles.delay;
      btnText = '本週補回報';
      break;
    case 3:
      btnStyle = styles.notyet;
      btnText = '本週尚未回報';
      break;
    default:
      btnStyle = styles.notyet;
      btnText = '本週尚未回報';
      break;
  }

  const handleReportButtonClick = () => {
    localStorage.setItem('ref_com', 'meetingReportPieRef');
    let path = homePath.PRMPathUrl('report_history');
    if (status === 3) {
      path = homePath.PRMPathUrl('service_report/add');
    }
    document.location.href = path;
  };

  return (
    <button
      className={cx(styles.btn, btnStyle)}
      onClick={handleReportButtonClick}
    >
      {btnText}
    </button>
  );
};

const RenderOrgList = ({ reportData }) => {
  const { date_range, lbl_desc, series_data } = reportData;
  const stateArray = [...Array(3).keys()];

  return (
    <>
      {series_data.length > 0 && (
        <Box pb={2} height="100%">
          <Box className={styles.title}>{date_range}</Box>
          <Box className={styles.sub_title}>{lbl_desc}</Box>
          <Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              className={styles.org_content}
            >
              {stateArray.map((i, k) => (
                <OrgListByState
                  key={k}
                  selectData={series_data.filter(
                    (x) => Number(x.data) === Number(i)
                  )}
                  state={i}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
      {series_data.length === 0 && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          pb={2}
          height="100%"
        >
          <Box className={styles.title}>{date_range}</Box>
          <Box className={styles.sub_title}>{lbl_desc}</Box>
          <Box height="35vh">
            <CheckCircleIcon className={styles.success_icon} />
          </Box>
          <Box>
            <ReportButton status={0} />
          </Box>
        </Box>
      )}
    </>
  );
};

const OrgListByState = ({ selectData = [], state }) => {
  let stateText, stateStyle, itemStyle;
  // const itemCount = selectData.filter(x=> Number(x.data) === Number(state)).length;
  const itemCount = selectData.length;
  switch (Number(state)) {
    case 0:
      stateText = '主日&小組皆未回報';
      stateStyle = styles.c_mix;
      itemStyle = styles.b_mix;
      break;
    case 1:
      stateText = '主日未回報';
      stateStyle = styles.c_orange;
      itemStyle = styles.b_orange;
      break;
    case 2:
      stateText = '小組未回報';
      stateStyle = styles.c_blue;
      itemStyle = styles.b_blue;
      break;
    default:
      break;
  }
  return (
    <>
      {selectData.length > 0 && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box className={cx(styles.state_text_v1, stateStyle)}>
              {stateText}({itemCount})
            </Box>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            <Box style={{ paddingTop: '10px' }}>
              <Grid container spacing={1}>
                {selectData.map((item, k) => (
                  <Grid key={k} item xs={6}>
                    <Box className={cx(styles.item, itemStyle)}>
                      {item.name}
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </>
  );
};

const MeetingReportPie = () => {
  const [reportData, setReportData] = useState({});
  const [circularloading, setCircularLoading] = useState(false);
  const [mode, setMode] = useState('chart'); //reportData.result_status:0-3(chart);4(list)

  useEffect(() => {
    setCircularLoading(true);
    //prm_meeting_report_data1_url
    const apiBody = {
      // "target" : "2020-11-29",
      // "target_range" : 4,
      // "setdate" : "2020-11-27"
    };
    apiCaller
      .apiFetch(config.prm_meeting_report_data1_url, {
        method: 'POST',
        body: JSON.stringify(apiBody),
      })
      .then((response) => {
        let result = response.Result;
        setReportData(result);
        if (Number(result.result_status) === 4) {
          setMode('list');
        }
        setCircularLoading(false);
      })
      .catch((err) => {
        setCircularLoading(false);
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  }, []);

  return (
    <Paper className={styles.card}>
      <CHeader titleName="主日/小組回報" imgPath={<AlarmOnIcon />} />
      <div
        className={
          mode === 'list'
            ? cx(styles.card_content, styles.list_mode)
            : cx(styles.card_content)
        }
      >
        {circularloading && <CLoading />}
        {!circularloading && Object.keys(reportData).length === 0 && <NoData />}
        {!circularloading && Object.keys(reportData).length > 0 && (
          <React.Fragment>
            {mode === 'chart' && (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-around"
                pb={2}
                height="100%"
              >
                <Box className={styles.title}>{reportData.date_range}</Box>
                <Box className={styles.sub_title}>{reportData.lbl_desc}</Box>
                <Box>
                  <PieChart
                    seriesData={reportData.series_data}
                    result_status={reportData.result_status}
                  />
                </Box>
                <Box pb={1} className={styles.stutas}>
                  {returnResultText(reportData.result_status)}
                </Box>
                <Box className={styles.note}>
                  將滑鼠移往圖表部分，會有更多資訊
                </Box>
                <Box>
                  <ReportButton status={reportData.status} />
                </Box>
              </Box>
            )}
            {mode === 'list' && <RenderOrgList reportData={reportData} />}
          </React.Fragment>
        )}
      </div>
    </Paper>
  );
};

export default MeetingReportPie;
