import decode from "jwt-decode";
import "whatwg-fetch";

const checkStatus = (response) => {
  // raises an error in case response status is not a success
  if (response.status >= 200 && response.status < 300) {
    // Success status lies between 200 to 300
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

const apiFetch = (url, options) => {
  // performs api calls sending the required authentication headers
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // Setting Authorization header
  // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
  if (isAuthenticated()) {
    headers.Authorization = `Bearer ${getToken()}`;
  }

  return fetch(url, {
    headers,
    ...options,
  })
    .then(checkStatus)
    .then((response) => response.json());
};
// For Setting Authorization header--Start--
const isAuthenticated = () => {
  // Checks if there is a saved token and it's still valid
  const token = getToken(); // GEtting token from localstorage
  return !!token && !isTokenExpired(token); // handwaiving here
};

const isTokenExpired = (token) => {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired. N
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};

const getToken = () =>
  // Retrieves the user token from localStorage
  localStorage.getItem("id_token");
// For Setting Authorization header--End--

// const apiFetchFile = (url, options) => {
//   // performs api calls sending the required authentication headers
//   const headers = {
//     "Content-Type": "application/json",
//     // responseType: 'blob', // important
//   };

//   // Setting Authorization header
//   // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
//   if (isAuthenticated()) {
//     headers.Authorization = `Bearer ${getToken()}`;
//   }

//   return fetch(url, {
//     headers,
//     ...options,
//   }).then((response) => response);
// };

const apiFetchFile = (url) => {
  // performs api calls sending the required authentication headers
  const headers = {
    Accept: "application/json",
    // "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
  };
  // const myHeaders = new Headers();

  // Setting Authorization header
  // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
  if (isAuthenticated()) {
    headers["Authorization"] = `Bearer ${getToken()}`;
  }
  return fetch(url, {
    method: "GET",
    headers,
  }).then((response) => response);
};

export default {
  apiFetch,
  apiFetchFile,
  checkStatus,
};
