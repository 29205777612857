import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//Material-UI
import {
  Container,
  Box,
  Grid,
  FormLabel,
  FormControl,
  FormHelperText,
  OutlinedInput,
} from '@mui/material';
//Sweetalert2
import { SwalWithCustomButtons } from '../../vendors/customSwal';
//custom css
import styles from './styles.module.scss';
//js
import homePath from '../../utils/homePath';
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';
//import component
import Header from '../../components/header';
import Footer from '../../components/footer';
import LoadingSpinner from '../../components/LoadingSpinner';

const AccountFP = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [pwDetail, setPwDetail] = useState({ old_password: '', password: '' });
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    setIsLoading(true);
    //api
    apiCaller
      .apiFetch(config.account_profile_url, {
        method: 'POST',
        body: '',
      })
      .then((response) => {
        setProfile(response.Result[0]);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  }, []);

  const handleAccountInfoClick = () => {
    const path = '/InternalSys/accountInfoChange';
    navigate(path);
  };

  const handlePwDetailChange = (e) => {
    const keys = e.target.name;
    const value = e.target.value;
    setPwDetail({ ...pwDetail, [keys]: value });
  };

  const handleHomePage = () => {
    const url = homePath.homePathUrl();
    document.location.href = url;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (pwDetail.new_password !== pwDetail.password) {
      setPwDetail({ ...pwDetail, password: '' });
      SwalWithCustomButtons.fire({
        title: '密碼不相符，請重新輸入',
        icon: 'error',
      });
      return false;
    }

    const apiBody = {
      old_password: pwDetail.old_password,
      password: pwDetail.password,
    };
    // console.log(JSON.stringify(apiBody))
    // account_chgpw_url
    apiCaller
      .apiFetch(config.account_chgpw_url, {
        method: 'POST',
        body: JSON.stringify(apiBody),
      })
      .then((response) => {
        SwalWithCustomButtons.fire({
          title: '密碼已更新',
          text: '請妥善保管，下次登入請記得使用新的密碼',
          icon: 'success',
        }).then(() => {
          handleHomePage();
        });
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        httpStatus.errorStatus(err.response);
      });
  };

  return (
    <div className={styles.page}>
      {isLoading && <LoadingSpinner />}
      <Header />
      <div className={styles.wrapper}>
        <Container maxWidth="md" className={styles.container}>
          <Box className={styles.page_title}>變更資訊</Box>
          <Box my={2} className={styles.box_body}>
            <Box className={styles.title_box}>
              <Box className={styles.title}>更改密碼</Box>
              <Box>
                <button
                  className={styles.outline_btn}
                  onClick={handleAccountInfoClick}
                >
                  變更個人資料
                </button>
              </Box>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box className={styles.content}>
                <Grid container>
                  <Grid item xs={12} sm={2} className={styles.form_text}>
                    <FormLabel className={styles.form_lable_text}>
                      電子郵件
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={10} className={styles.form_input}>
                    <Box component="span" className={styles.black_text}>
                      {profile.email}
                    </Box>
                    {/* <FormControl margin="dense" variant="outlined">
                                        <OutlinedInput  name="user_email" type="email" value={profile.user_email || ""} onChange={handleProfileChange} />
                                    </FormControl> */}
                  </Grid>
                  <Grid item xs={12} sm={2} className={styles.form_text}>
                    <FormLabel className={styles.form_lable_text}>
                      目前的密碼
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={6} className={styles.form_input}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <OutlinedInput
                        name="old_password"
                        type="password"
                        value={pwDetail.old_password || ''}
                        onChange={handlePwDetailChange}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} className={styles.form_text}></Grid>
                  <Grid item xs={12} sm={2} className={styles.form_text}>
                    <FormLabel className={styles.form_lable_text}>
                      新密碼
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={6} className={styles.form_input}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <OutlinedInput
                        name="new_password"
                        type="password"
                        value={pwDetail.new_password || ''}
                        onChange={handlePwDetailChange}
                        inputProps={{
                          pattern: '(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,12}',
                          title: '長度在6~12,需包含數字、小寫英文、大寫英文',
                        }}
                        required
                      />
                      <FormHelperText>
                        長度在6~12,需包含數字、小寫英文、大寫英文
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} className={styles.form_text}></Grid>
                  <Grid item xs={12} sm={2} className={styles.form_text}>
                    <FormLabel className={styles.form_lable_text}>
                      確認新密碼
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={6} className={styles.form_input}>
                    <FormControl margin="dense" variant="outlined" fullWidth>
                      <OutlinedInput
                        name="password"
                        type="password"
                        value={pwDetail.password || ''}
                        onChange={handlePwDetailChange}
                        required
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4} className={styles.form_text}></Grid>
                </Grid>
              </Box>
              <Box className={styles.btn_box}>
                <button className={styles.fill_btn} type="submit">
                  密碼變更
                </button>
              </Box>
            </form>
          </Box>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default AccountFP;
