import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import PropTypes from 'prop-types';
import cx from 'classnames';
//Material-UI
import { Box, Paper } from '@mui/material';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

//custom css
import styles from './styles.module.scss';
//components
import CHeader from '../../components/card/cHeader';
import CFooter from '../../components/card/cFooter';
import DrpOrg from '../../components/drpOrg';
import NoData from '../../components/card/noData';
import CLoading from '../../components/card/cLoading';
//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const DayItem = ({ birth_week, birth_day, name, if_highlight }) => {
  //補0
  const paddingLeft = (str, length) => {
    if (str.length >= length) return str;
    else return paddingLeft('0' + str, length);
  };

  return (
    <Box className={styles.day_item}>
      <Box className={cx(styles.item, styles.week)}>{birth_week}</Box>
      <Box
        className={
          if_highlight
            ? cx(styles.item, styles.date, styles.highlight)
            : cx(styles.item, styles.date)
        }
      >
        {paddingLeft(String(birth_day), 2)}
      </Box>
      <Box className={cx(styles.item)}>{`${name} 生日快樂`}</Box>
    </Box>
  );
};

const Month = ({ month, birthdayData }) => {
  return (
    <Box>
      <Box className={styles.month}>
        {birthdayData.length > 0 && `${month}月份`}
      </Box>
      {birthdayData.map((item, index) => (
        <DayItem
          key={index}
          birth_week={item.birth_week}
          birth_day={item.birth_day}
          name={item.member_name}
          if_highlight={item.if_highlight}
        />
      ))}
    </Box>
  );
};

const BirthdayList = () => {
  const monthOrder = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // const [birthdayData, setBirthdayData] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [curOrgAid, setCurOrgAid] = useState(0);
  // const [circularloading, setCircularLoading] = useState(false);
  // const [monthData, setMonthData] = useState(monthOrder);

  const returnNumber = (n) => {
    if (n > 12) {
      return n - 12;
    } else {
      return n;
    }
  };

  const birthdayFetcher = ([url, data]) =>
    apiCaller
      .apiFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .then((response) => {
        let newMonthOrder = monthOrder;
        if (response.Result.length > 0) {
          let startMonth = Number(response.Result[0].birth_month);
          newMonthOrder = monthOrder.map((i, k) => {
            return returnNumber(Number(startMonth) + k);
          });
        }
        return { birthdayData: response?.Result, monthData: newMonthOrder };
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
      });

  const { data, isLoading: circularLoading } = useSWR(
    [config.cg_member_birthday_url, { org_aid: Number(curOrgAid) }],
    birthdayFetcher,
    {
      fallbackData: { birthdayData: [], monthData: [] },
    }
  );

  // useEffect(() => {
  //   setCircularLoading(true);
  //   //audit_list_url
  //   apiCaller
  //     .apiFetch(config.cg_member_birthday_url, {
  //       method: 'POST',
  //       body: JSON.stringify({ org_aid: Number(curOrgAid) }),
  //     })
  //     .then((response) => {
  //       setBirthdayData(response.Result);
  //       if (response.Result.length > 0) {
  //         let startMonth = Number(response.Result[0].birth_month);
  //         let newMonthOrder = monthOrder.map((i, k) => {
  //           return returnNumber(Number(startMonth) + k);
  //         });
  //         setMonthData(newMonthOrder);
  //       }
  //       setCircularLoading(false);
  //     })
  //     .catch((err) => {
  //       setCircularLoading(false);
  //       console.log(err);
  //       httpStatus.errorStatus(err.response);
  //     });
  // }, [curOrgAid]);

  return (
    <Paper className={styles.card}>
      <CHeader
        titleName="生日通知"
        imgPath={<CardGiftcardIcon />}
        headerType="2"
        drpOrg={
          <DrpOrg
            orgType="1"
            orgList={orgList}
            setOrgList={setOrgList}
            curOrgAid={curOrgAid}
            setCurOrgAid={setCurOrgAid}
            refName="birthdayList"
          />
        }
      />
      <div className={styles.card_content}>
        {circularLoading && <CLoading />}
        {!circularLoading && data?.birthdayData.length === 0 && <NoData />}
        {!circularLoading &&
          data?.birthdayData.length > 0 &&
          data?.monthData.map((value, index) => {
            let bymonth = data?.birthdayData.filter((item, j) => {
              return item.birth_month === value;
            });
            if (bymonth.length > 0) {
              return <Month key={index} month={value} birthdayData={bymonth} />;
            }
            return false;
          })}
      </div>
      <CFooter footerType="1" totalPage={data?.birthdayData.length} />
    </Paper>
  );
};

Month.propTypes = {
  month: PropTypes.number,
  birthdayData: PropTypes.array,
};
DayItem.propTypes = {
  birth_week: PropTypes.string,
  birth_day: PropTypes.number,
  name: PropTypes.string,
  if_highlight: PropTypes.bool,
};

export default BirthdayList;
