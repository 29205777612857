import React from 'react';
//Material-UI
import { Box } from '@mui/material';

const NoData = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        border: ' solid 2px #f2f3f8',
        borderRadius: '5.7px',
        fontSize: '18px',
        color: '#aaaaaa',
      }}
    >
      目前尚無資訊
    </Box>
  );
};

export default NoData;
