import React, { useState, useEffect } from 'react';
//Material-UI
import { Box, Paper, Divider } from '@mui/material';
import HowToRegIcon from '@mui/icons-material/HowToReg';

//custom css
import styles from './styles.module.scss';

//components
import CHeader from '../../components/card/cHeader';
import CFooter from '../../components/card/cFooter';
import DrpOrg from '../../components/drpOrg';
import AccountItem from '../../components/card/accountItem';
import NoData from '../../components/card/noData';
import CLoading from '../../components/card/cLoading';
import { useWindowSize } from '../../utils/useWindowSize';
//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';
import useSWR from 'swr';

const AccountManager = () => {
  const [orgList, setOrgList] = useState([]);
  const [curOrgAid, setCurOrgAid] = useState(0);
  const [page, setPage] = useState(1);
  const size = useWindowSize();
  const splitItem = size.width >= 1200 ? 8 : 3;

  const fetcher = ([url, data]) =>
    apiCaller
      .apiFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .then((response) => {
        // console.log(response.Result);
        return response.Result;
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
        return err;
      });

  const { data: rolesList, isLoading: circularLoading } = useSWR(
    curOrgAid !== 0
      ? [
          config.account_group_privilege_info_url,
          { org_aid: Number(curOrgAid) },
        ]
      : null,
    fetcher,
    {
      fallbackData: [],
    }
  );

  useEffect(() => {
    if (curOrgAid !== 0) {
      setPage(1);
    }
  }, [curOrgAid]);

  return (
    <Paper className={styles.card}>
      <CHeader
        titleName="帳號管理"
        imgPath={<HowToRegIcon />}
        headerType="3"
        drpOrg={
          <DrpOrg
            orgType="3"
            orgList={orgList}
            setOrgList={setOrgList}
            curOrgAid={curOrgAid}
            setCurOrgAid={setCurOrgAid}
            refName="accountManager"
          />
        }
      />
      <div className={styles.card_content}>
        {circularLoading && <CLoading />}
        {!circularLoading && rolesList.length === 0 && <NoData />}
        {!circularLoading &&
          rolesList.length > 0 &&
          rolesList
            .slice((page - 1) * splitItem, (page - 1) * splitItem + splitItem)
            .map((item, index) => {
              return index + 1 === (page - 1) * 4 + 4 ||
                index ===
                  rolesList.slice(
                    (page - 1) * splitItem,
                    (page - 1) * splitItem + splitItem
                  ).length -
                    1 ? (
                <Box key={`a_${index}`} className={styles.helf}>
                  <AccountItem accountContent={item} orgAid={curOrgAid} />
                </Box>
              ) : (
                <Box key={`a_${index}`} className={styles.helf}>
                  <AccountItem accountContent={item} orgAid={curOrgAid} />
                  <Divider variant="middle" />
                </Box>
              );
            })}
      </div>
      <CFooter
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(rolesList.length / splitItem)}
      />
    </Paper>
  );
};

export default AccountManager;
