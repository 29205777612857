import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
//Material-UI
import { Box, Paper, Divider } from '@mui/material';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
//custom css
import styles from './styles.module.scss';
//components
import CHeader from '../../components/card/cHeader';
import CFooter from '../../components/card/cFooter';
import CLoading from '../../components/card/cLoading';
import NoData from '../../components/card/noData';
import MemberItem from '../../components/card/memberItem';
import DrpOrg from '../../components/drpOrg';
import { useWindowSize } from '../../utils/useWindowSize';

//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const MemberList = () => {
  // const [mList, setMList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [curOrgAid, setCurOrgAid] = useState(0);
  // const [circularloading, setCircularLoading] = useState(false);
  const [ifShowPrmOther, setIfShowPrmOther] = useState('3'); // 影響到cHeader，小家長沒有新增權限
  const [page, setPage] = useState(1);
  const size = useWindowSize();
  const splitItem = size.width >= 1200 ? 8 : 3;
  // console.log(curOrgAid)
  const memberApiInit = {
    org_aid: curOrgAid,
    if_follow: -1,
    if_follow_feq: 4,
    if_audit: -1,
    if_nf: -1,
  };

  const memberListFetcher = ([url, data]) =>
    apiCaller
      .apiFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .then((response) => {
        return response.Result;
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
        return err;
      });

  const { data: mList, isLoading: circularLoading } = useSWR(
    curOrgAid !== null
      ? [config.cg_member_list_status_url, memberApiInit]
      : null,
    memberListFetcher,
    {
      fallbackData: [],
    }
  );

  useEffect(() => {
    // api account_dashboard_view_url
    apiCaller
      .apiFetch(config.account_dashboard_view_url, {
        method: 'POST',
        body: '',
      })
      .then((response) => {
        if (response.Result.if_show_prm_other === 1) {
          setIfShowPrmOther('4');
        }
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
      });

    if (curOrgAid !== null) {
      setPage(1);
    }
  }, [curOrgAid]);

  return (
    <Paper className={styles.card}>
      <CHeader
        titleName="成員列表"
        imgPath={<PeopleAltIcon />}
        headerType={ifShowPrmOther}
        drpOrg={
          <DrpOrg
            orgType="2"
            orgList={orgList}
            setOrgList={setOrgList}
            curOrgAid={curOrgAid}
            setCurOrgAid={setCurOrgAid}
            refName="memberList"
          />
        }
      />
      <div className={styles.card_content}>
        {circularLoading && <CLoading />}
        {!circularLoading && mList.length <= 0 && <NoData />}
        {!circularLoading &&
          mList.length > 0 &&
          mList
            .slice((page - 1) * splitItem, (page - 1) * splitItem + splitItem)
            .map((item, index) => {
              return index + 1 === 4 ||
                index ===
                  mList.slice(
                    (page - 1) * splitItem,
                    (page - 1) * splitItem + splitItem
                  ).length -
                    1 ? (
                <Box key={`a_${index}`} className={styles.helf}>
                  <MemberItem memberContent={item} />
                </Box>
              ) : (
                <Box key={`a_${index}`} className={styles.helf}>
                  <MemberItem memberContent={item} />
                  <Divider variant="middle" />
                </Box>
              );
            })}
      </div>
      <CFooter
        page={page}
        setPage={setPage}
        totalPage={Math.ceil(mList.length / splitItem)}
      />
    </Paper>
  );
};

export default MemberList;
