import React from 'react';
//Material-UI
import { Box, CircularProgress } from '@mui/material';
//custom css
import styles from './styles.module.scss';

const CLoading = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress className={styles.buttonProgress} size={50} />
    </Box>
  );
};

export default CLoading;
