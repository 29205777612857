import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import cx from 'classnames';
//Material-UI
import {
  Box,
  Paper,
  Grid,
  Popover,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
//icon
import FlagIcon from '@mui/icons-material/Flag';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SchoolIcon from '@mui/icons-material/School';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

//components
import CHeader from '../../components/card/cHeader';
import DrpOrg from '../../components/drpOrg';
import NoData from '../../components/card/noData';
import CLoading from '../../components/card/cLoading';
import { useWindowSize } from '../../utils/useWindowSize';
//custom css
import styles from './styles.module.scss';
//js
import apiCaller from '../../utils/apiCaller';
import config from '../../config';
import httpStatus from '../../services/httpStatus';

const CgMemberDetail = ({ com, courseDetail }) => {
  const customStyles =
    com === 'popover' ? styles.popover : styles.timeline_cg_content;
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Box py={1} className={customStyles}>
      {courseDetail.cos_member_list.length > 0 && (
        <Box className={styles.cg_people_count}>
          共{courseDetail.cos_member_list.length}人
        </Box>
      )}
      {courseDetail.cos_member_list.length <= 4 ? (
        <Box className={styles.cg_member_content}>
          {courseDetail.cos_member_list.join('、')}
        </Box>
      ) : (
        <Box className={styles.cg_member_content}>
          <Button className={styles.btn_more} onClick={handleDialogOpen}>
            觀看所有
          </Button>
          <Dialog
            fullWidth={true}
            maxWidth="sm"
            open={openDialog}
            onClose={handleDialogClose}
            scroll={'paper'}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              className={styles.dialog_title}
            >
              <span className={styles.title_text}>TU聖經學院狀態</span>
            </DialogTitle>
            <DialogContent>
              <Box className={styles.dialog_subtitle}>
                {courseDetail.course_name}
              </Box>
              <Box style={{ padding: 20 }}>
                <Grid container spacing={3} className={styles.dialog_content}>
                  {courseDetail.cos_member_list.map((item, index) => (
                    <Grid item key={index} xs={4}>
                      <span>{item}</span>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button className={styles.dialog_btn} onClick={handleDialogClose}>
                關閉
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </Box>
  );
};

const PopoverContent = ({
  PopoverId,
  anchorEl,
  openedPopoverId,
  handlePopoverClose,
  courseDetail,
}) => (
  <Popover
    id={PopoverId}
    open={openedPopoverId === PopoverId}
    anchorEl={anchorEl}
    onClose={handlePopoverClose}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
  >
    <CgMemberDetail com="popover" courseDetail={courseDetail} />
  </Popover>
);

const RenderWebCourse = ({ memberStatusDetail }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openedPopoverId, setOpenedPopoverId] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenedPopoverId(event.currentTarget.id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenedPopoverId(null);
  };

  return (
    <React.Fragment>
      <ul className={styles.timeline}>
        {memberStatusDetail.map((item, index) => (
          <RenderWebCourseItem
            key={index}
            index={index}
            totalLength={memberStatusDetail.length}
            courseDetail={item}
            handleClick={handleClick}
          />
        ))}
      </ul>
      {memberStatusDetail.map((item, index) => {
        if (item.cos_member_list.length !== 0) {
          return (
            <PopoverContent
              key={`p_${index}`}
              PopoverId={`s_${index}`}
              anchorEl={anchorEl}
              openedPopoverId={openedPopoverId}
              handlePopoverClose={handlePopoverClose}
              courseDetail={item}
            />
          );
        }
      })}
    </React.Fragment>
  );
};

const RenderWebCourseItem = ({
  index,
  totalLength,
  courseDetail,
  handleClick,
}) => {
  const hasMember = courseDetail.cos_member_list.length > 0 ? true : false;
  let typeStyle = null;
  let iconComponent = <DirectionsRunIcon fontSize="large" />;
  if (index === 0) {
    typeStyle = styles.first;
    iconComponent = <FlagIcon fontSize="large" />;
  } else if (index === totalLength - 1) {
    typeStyle = styles.last;
    iconComponent = <EmojiEventsIcon fontSize="large" />;
  }
  return (
    <li
      className={cx(styles.timeline_item)}
      id={`s_${index}`}
      onClick={handleClick}
    >
      <div className={styles.item_wrapper}>
        <div
          className={
            hasMember
              ? cx(styles.item_icon, typeStyle, styles.light)
              : cx(styles.item_icon, typeStyle)
          }
        >
          <div className={cx(styles.item_big_dots, typeStyle)}>
            <span
              className={
                hasMember ? cx(styles.dots, styles.light) : styles.dots
              }
            ></span>
          </div>
          {iconComponent}
        </div>
        <div className={cx(styles.item_text, typeStyle)}>
          {courseDetail.course_name}
        </div>
      </div>
    </li>
  );
};

const RenderPhoneCourse = ({ memberStatusDetail }) => {
  return (
    <React.Fragment>
      <ul className={styles.timeline_v}>
        {memberStatusDetail.map((item, index) => (
          <RenderPhoneCourseItem
            key={index}
            index={index}
            totalLength={memberStatusDetail.length}
            courseDetail={item}
          />
        ))}
      </ul>
    </React.Fragment>
  );
};

const RenderPhoneCourseItem = ({ index, totalLength, courseDetail }) => {
  const hasMember = courseDetail.cos_member_list.length > 0 ? true : false;
  let iconComponent = <DirectionsRunIcon fontSize="large" />;
  if (index === 0) {
    iconComponent = <FlagIcon fontSize="large" />;
  } else if (index === totalLength - 1) {
    iconComponent = <EmojiEventsIcon fontSize="large" />;
  }
  return (
    <li key={index} className={cx(styles.timeline_item)} id={`s_${index}`}>
      <div
        className={
          index !== totalLength - 1
            ? cx(styles.item_wrapper)
            : cx(styles.item_wrapper, styles.last_child)
        }
      >
        <div className={cx(styles.item_big_dots)}>
          <span
            className={hasMember ? cx(styles.dots, styles.light) : styles.dots}
          ></span>
        </div>
        <div
          className={
            hasMember
              ? cx(styles.item_icon, styles.light)
              : cx(styles.item_icon)
          }
        >
          {iconComponent}
        </div>
        <div className={cx(styles.item_content)}>
          <div className={styles.timeline_cg_title}>
            {courseDetail.course_name}
          </div>
          <CgMemberDetail
            com="timeline_cg_content"
            courseDetail={courseDetail}
          />
        </div>
      </div>
    </li>
  );
};

const MemberTUBibleStatus = () => {
  const [orgList, setOrgList] = useState([]);
  // const [memberStatus, setMemberStatus] = useState([]);
  const [curOrgAid, setCurOrgAid] = useState(0);
  // const [circularloading, setCircularLoading] = useState(false);

  const size = useWindowSize();

  const fetcher = ([url, data]) =>
    apiCaller
      .apiFetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
      })
      .then((response) => {
        // console.log(response.Result);
        let result = response.Result;
        return result;
      })
      .catch((err) => {
        console.log(err);
        httpStatus.errorStatus(err.response);
        return err;
      });
  const { data: memberStatus, isLoading: circularLoading } = useSWR(
    curOrgAid !== 0
      ? [
          config.cg_member_list_tu_status_url,
          {
            org_aid: Number(curOrgAid),
          },
        ]
      : null,
    fetcher,
    { fallbackData: [] }
  );

  return (
    <Paper className={styles.card}>
      <CHeader
        titleName="TU聖經學院狀態"
        imgPath={<SchoolIcon />}
        headerType="3"
        drpOrg={
          <DrpOrg
            orgType="3"
            orgList={orgList}
            setOrgList={setOrgList}
            curOrgAid={curOrgAid}
            setCurOrgAid={setCurOrgAid}
            refName="memberTU"
          />
        }
      />
      <div className={styles.card_content}>
        {circularLoading && <CLoading />}
        {!circularLoading && memberStatus.length === 0 && <NoData />}
        {!circularLoading && memberStatus.length > 0 && size.width >= 1200 && (
          //web
          <RenderWebCourse memberStatusDetail={memberStatus} />
        )}
        {!circularLoading && memberStatus.length > 0 && size.width < 1200 && (
          // phone
          <RenderPhoneCourse memberStatusDetail={memberStatus} />
        )}
      </div>
    </Paper>
  );
};

export default MemberTUBibleStatus;
